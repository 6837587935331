<a name="Fights" id="Fights"></a>
<div class="callout">
  <h1>Fight History</h1>

  <ol>
    <li>
      <p><span style="font-size:small">You can battle other players on the <a [routerLink]="" (click)="fight()">Battle</a> page.</span></p>

      <ng-container *ngIf="this.fightHistory && this.fightHistory.length == 0"><p><strong>No Fight History.</strong></p></ng-container>
    </li>

    <li *ngFor="let fight of fightHistory">
      <span class="small-text">({{fight?.time}})&nbsp;</span>

      <ng-container *ngIf="fight?.isOffensePlayerWinner">
        <strong>You were attacked by <a [routerLink]="['/player', fight?.offensePlayerGroupName]">{{fight?.offensePlayerName}}</a> and Lost.</strong><br />
        You lost ç{{fight?.creditsExchanged | commaSeparatedNumber}}.<br />
        It cost {{fight?.defenseHealthLost}} health to heal your injuries.<br />
        You received {{fight?.looserExperienceGained}} experience for fighting.
      </ng-container>
      <ng-container *ngIf="! fight?.isOffensePlayerWinner">
        <strong>You were attacked by <a [routerLink]="['/player', fight?.offensePlayerGroupName]">{{fight?.offensePlayerName}}</a> and Won.</strong><br />
        You won ç{{fight?.creditsExchanged | commaSeparatedNumber}}.<br />
        It cost {{fight?.defenseHealthLost}} health to heal your injuries.<br />
        You received {{fight?.winnerExperienceGained}} experience for fighting.<br />
      </ng-container>

      <p>
        <button (click)="fightBack(fight?.id)" [disabled]="fight?.active" class="form-button" type="button">
          <span [className]="fight?.active ? 'spinner-border spinner-border-sm' : ''"></span>
          {{fight?.active ? 'Fighting...' : 'Fight Back!'}}
        </button>
        <ngb-alert *ngIf="fight?.resultText" (close)="fight.resultText = null" type="danger">
          <div [innerHTML]="fight?.resultText"></div>
        </ngb-alert>
      </p>

      <!-- NOTE: DO NOT LINK TO CHAT HERE. Player can only chat with people in their group, not random players. -->

    </li>

  </ol>

</div>
