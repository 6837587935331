import { Component, OnInit, Input } from '@angular/core';
import { PlayerService } from '../_services/player.service';
import { Dashboard } from '../_models/dashboard';
import { Observable } from 'rxjs/Rx';
import { Constants } from '../_shared/constants';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dashboard: Dashboard;
  data: Datastore;

  constructor(
    private playerService: PlayerService,
    private datastore: DatastoreService) {

    this.datastore.register().subscribe((data) => {
      this.data = data;
    });
  }

  currentTaskSeconds: number;
  maxTaskSeconds: number;

  currentFightSeconds: number;
  maxFightSeconds: number;

  currentHealthSeconds: number;
  maxHealthSeconds: number;

  currentAssetSeconds: number;
  maxAssetSeconds: number;

  ngOnInit() {
    this.playerService.getDashboard()
      .subscribe(result => {
        this.dashboard = result.value;

        // Initialize datastore
        var initialStore: Datastore = new Datastore(
          "", // Role
          this.dashboard.level,
          this.dashboard.experience,
          this.dashboard.levelStartsAt,
          this.dashboard.levelEndsAt,
          this.dashboard.levelPercentComplete,
          this.dashboard.availableCredits,
          this.dashboard.fightCredits,
          this.dashboard.healthCredits,
          this.dashboard.taskCredits,
          this.dashboard.assetIncrement,
          this.dashboard.unallocatedSkillPoints,
          this.dashboard.maxFightCredits,
          this.dashboard.maxHealthCredits,
          this.dashboard.maxTaskCredits,
          this.dashboard.offensePoints,
          this.dashboard.defensePoints,
          0,
          0,
          Constants.InitialGuestRegistrationTeaserLevel
        );
        this.datastore.initializeDatastore(initialStore);

        this.currentTaskSeconds = this.dashboard.taskIncrementSecondsRemaining;
        this.maxTaskSeconds = Constants.minutesBetweenTaskCreditIncrement;
        let taskTimer = Observable.timer(1000, 1000);
        taskTimer.subscribe(t => {
          this.updateTaskPoints(t);
        });

        this.currentFightSeconds = this.dashboard.fightIncrementSecondsRemaining;
        this.maxFightSeconds = Constants.minutesBetweenFightCreditIncrement;
        let fightTimer = Observable.timer(1000, 1000);
        fightTimer.subscribe(t => {
          this.updateFightPoints(t);
        });

        this.currentHealthSeconds = this.dashboard.healthIncrementSecondsRemaining;
        this.maxHealthSeconds = Constants.minutesBetweenHealthCreditIncrement;
        let healthTimer = Observable.timer(1000, 1000);
        healthTimer.subscribe(t => {
          this.updateHealthPoints(t);
        });

        this.currentAssetSeconds = this.dashboard.assetIncrementSecondsRemaining;
        this.maxAssetSeconds = Constants.minutesBetweenAssetCreditIncrement;
        let assetTimer = Observable.timer(1000, 1000);
        assetTimer.subscribe(t => {
          this.updateCredits(t);
        });

      });
  }

  private updateTaskPoints(any): void {
    this.currentTaskSeconds--;
    if (this.currentTaskSeconds <= 0) {
      // Timer ran out. Reset.
      this.currentTaskSeconds = this.maxTaskSeconds;
      this.datastore.incrementTaskCredits(1);
    }
    // Check if we're at max. If so, stop timer and hide timer and exit.
    if (this.data.taskCredits >= this.data.maxTaskCredits) {
      // Stop and hide timer
      this.data.taskCredits = this.data.maxTaskCredits;
      this.dashboard.taskIncrementSecondsRemaining = 0;
      return;
    }
    else {
      // Decrement timer
      this.dashboard.taskIncrementSecondsRemaining = this.currentTaskSeconds;
    }
  }

  private updateFightPoints(any): void {
    this.currentFightSeconds--;
    if (this.currentFightSeconds <= 0) {
      // Timer ran out. Reset.
      this.currentFightSeconds = this.maxFightSeconds;
      this.datastore.incrementFightCredits(1);
    }
    // Check if we're at max. If so, stop timer and hide timer and exit.
    if (this.data.fightCredits >= this.data.maxFightCredits) {
      // Stop and hide timer
      this.data.fightCredits = this.data.maxFightCredits;
      this.dashboard.fightIncrementSecondsRemaining = 0;
      return;
    }
    else {
      // Decrement timer
      this.dashboard.fightIncrementSecondsRemaining = this.currentFightSeconds;
    }
  }

  private updateHealthPoints(any): void {
    this.currentHealthSeconds--;
    if (this.currentHealthSeconds <= 0) {
      // Timer ran out. Reset.
      this.currentHealthSeconds = this.maxHealthSeconds;
      this.datastore.incrementHealthCredits(1);
    }
    // Check if we're at max. If so, stop timer and hide timer and exit.
    if (this.data.healthCredits >= this.data.maxHealthCredits) {
      // Stop and hide timer
      this.data.healthCredits = this.data.maxHealthCredits;
      this.dashboard.healthIncrementSecondsRemaining = 0;
      return;
    }
    else {
      // Decrement timer
      this.dashboard.healthIncrementSecondsRemaining = this.currentHealthSeconds;
    }
  }

  private updateCredits(any): void {
    this.currentAssetSeconds--;
    if (this.currentAssetSeconds <= 0) {
      // Timer ran out. Reset.
      this.currentAssetSeconds = this.maxAssetSeconds;
      this.datastore.incrementCredits(this.data.assetIncome);
      
    }
    // Decrement timer
    this.dashboard.assetIncrementSecondsRemaining = this.currentAssetSeconds;
  }

}


