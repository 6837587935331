import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { Constants } from '../_shared/constants';
import { Login } from '../_models/login';
import { Registration } from '../_models/registration';
import { PlayerService } from '../_services/player.service';
import { PlayerSummary } from '../_models/playerSummary';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  model: Registration = new Registration("", "", "", "");
  errors: string[];
  private readonly jwtKey: string = Constants.JwtKey;
  isRegistering: boolean = false;
  playerName: string = "";

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private playerService: PlayerService,
    private title: Title) {
    title.setTitle("Play Space Pirates! Register");  }

  ngOnInit() {
    this.errors = [];
    this.playerService.getPlayerSummary('')
      .subscribe(result => {
        this.model.UserName = result.value.name;
        this.playerName = result.value.name;
      });
  }

  register() {
    this.isRegistering = true;
    this.errors = [];

    this.authenticationService.register(this.model).subscribe(
      success => {
        // User registered successfully.
        // First remove their existing guest JWT
        this.authenticationService.logoutGuest();

        // Save JWT to local storage to keep user logged in between sessions
        localStorage.setItem(this.jwtKey, JSON.stringify({ username: this.model.Email, token: success.token }));
        location.replace('/');
      },
      error => {
        this.isRegistering = false;
        if (error.status === 400) {
          let validationErrorDictionary = error.error;
          for (var fieldName in validationErrorDictionary) {
            if (validationErrorDictionary.hasOwnProperty(fieldName)) {
              var i:number = 0;
              for (i = 0; i < validationErrorDictionary[fieldName].length; i++) {
                this.errors.push(validationErrorDictionary[fieldName][i]);
              }
            }
          }
        } else {
          this.errors.push("An error occurred. Please try again.");
        }

      });

  }

}
