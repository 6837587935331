<app-main-sub-menu></app-main-sub-menu>

<app-user-management></app-user-management>
<p>&nbsp;</p>

<p><img src="../../assets/images/HomeImage.png" style="width: 100%" alt="Pirate Skull over earth and spaceship." /></p>
<p>&nbsp;</p>

<div>

  <div class="action">
    <img src="../../assets/images/TableTop.jpg" class="action-image-top" />

    <div class="action-inner">

      <div class="col" style="text-align: right;">
        <a href="#" onclick="window.open('https://www.sitelock.com/verify.php?site=playspacepirates.com','SiteLock','width=600,height=600,left=160,top=170');"><img class="img-responsive" alt="SiteLock" title="SiteLock" src="../../assets/images/SiteLock.png" width="85" /></a>
      </div>

      <h1>Play for Free!</h1>

      <div>
        <h2>If you liked Mafia Wars, you'll LOVE Space Pirates!</h2>
        <p style="font-size: larger;"><strong>Space Pirates</strong> is a retro-style MMRPG tycoon game in the spirit of Mafia Wars. Start as a land lubber, complete missions, level up and build strength to battle other players!</p>
        <p>
          Gameplay is extensive with 157 levels, 73 missions, more than 50 achievements and epic player vs. player battles. There's virtually no end to the fun.
          Space Pirates is free to play and has a companion Wiki site and an active community to help if you get stuck.
        </p>

        <!--<blockquote>I liked Mafia Wars. But I LOVE Space Pirates!</blockquote>-->
      </div>

      <div class="row">
        <div class="col-lg-4 col-sm-6 col-xs-6"><app-screenshot-carousel></app-screenshot-carousel></div>
        <div class="col-lg-8 col-sm-6 col-xs-6">

          <h2>Mafia Wars is dead.<br />Long Live Space Pirates!</h2>
          <img src="../../assets/images/ESRBEveryoneTenPlus.png" align="left" alt="ESRB Rating - Everyone - 10+" style="width: 75px; padding-right: 9px; padding-bottom: 7px;" />
          <p>The game Mafia Wars by game maker Zynga, was perhaps the single most popular game launched on Facebook. Unfortunately, in April, 2017, Mafia Wars was taken offline.</p>
          <p>While not the first, Mafia Wars was perhaps the most popular of all the MMRPG Boss style games. Space Pirates was created to fill the gap. Space Pirates gives you similar game play, but in an exciting space-faring civilization.</p>
          <p>Join the thousands who've played Space Pirates online. Now available on your browser - anywhere, on any device, anytime!</p>

          <h2>Reviews</h2>


          Space Pirates won't take up your entire day. You can jump in and play for 5-10 minutes at a time.
          <blockquote>The game you can play in just five minutes a day.</blockquote>

          <h3>Simple, yet fun:</h3>
          <blockquote>Eve Online was super complicated. I figured Space Pirates out in like 10 minutes and I've enjoyed playing ever since.</blockquote>

          <h3>And who can forget:</h3>
          <blockquote>What else are you going to do on the toilet?</blockquote>

          Set sail today! You're going to love it!
          <br />&nbsp;<br />

        </div>
      </div>

    </div>
    <img src="../../assets/images/TableBottom.jpg" class="action-image-bottom" />
  </div>

</div>

<app-user-management></app-user-management>

<p>&nbsp;</p>

<div style="text-align: center;">
<a href="#" onclick="window.open('https://www.sitelock.com/verify.php?site=playspacepirates.com','SiteLock','width=600,height=600,left=160,top=170');" ><img class="img-responsive" alt="SiteLock" title="SiteLock" src="//shield.sitelock.com/shield/playspacepirates.com" /></a>
</div>

<br /> &nbsp; <br />
