import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaSeparatedNumber'
})
export class CommaSeparatedNumberPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    if (value) {
      return value.toLocaleString("en");
    }
    return 0;
  }

}
