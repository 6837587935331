<div class="gameBoard">

  <app-header></app-header>

  <div class="callout">
    <h1>Fight!</h1>
    <h2>Give 'em yer broadsides!</h2>
  </div>

  <div class="callout" *ngIf="!(isFightingAvailable())">
    <span class="error">At Level {{fightingAvailableAtLevel()}} ye'll be fighting other players!</span>
  </div>

  <div *ngFor="let opponent of opponents">

    <div class="action">
      <img src="../../assets/images/TableTop.jpg" class="action-image-top" />
      <div class="action-inner">
        <h2><a [routerLink]="['/player', opponent?.groupName]">{{opponent?.name}}</a></h2>
        Level: {{opponent?.level}}<br />
        Group Size: {{opponent?.groupSize}}<br />
        Player Id: {{opponent?.groupName}}<br />&nbsp;<br />

        <button (click)="fight(opponent?.groupName)" [disabled]="opponent?.active" class="form-button" type="button">
          <span [className]="opponent?.active ? 'spinner-border spinner-border-sm' : ''"></span>
          {{opponent?.active ? 'Fighting...' : 'Fight !'}}
        </button>
        <ngb-alert *ngIf="opponent?.resultText" (close)="opponent.resultText = null" type="danger">
          <div [innerHTML]="opponent?.resultText"></div>
        </ngb-alert>

      </div>
      <img src="../../assets/images/TableBottom.jpg" class="action-image-bottom" />
    </div>

  </div>

</div>
