import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MessagingService } from '../_services/messaging.service';
import { ChatMessage } from '../_models/chatMessage';
import { PlayerService } from '../_services/player.service';
import { PlayerSummary } from '../_models/playerSummary';
import { Result } from '../_models/result';
import { Chat } from '../_models/chat';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private messagingService: MessagingService,
    private playerService: PlayerService
) { }

  groupName: string = '';
  chatMessages: ChatMessage[];
  recipient: PlayerSummary;
  player: PlayerSummary;
  message: string = '';
  result: Result<any>;

  ngOnInit() {
    try {
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          params.get('id'))
      ).subscribe(val => this.groupName += val);
    } catch (e) { /*Should always be a group name passed in. */
    }

    this.messagingService.getChatMessagesFromPlayer(this.groupName)
      .subscribe(result => {
        this.chatMessages = result.value;
      });

    this.playerService.getPlayerSummary(this.groupName)
      .subscribe(result => {
        this.recipient = result.value;
    });

    this.playerService.getPlayerSummary()
      .subscribe(result => {
        this.player = result.value;
      });
  }

  sendChat() {
    if (!this.message) {
      return;
    }

    var chat: Chat = {
      "groupName": this.groupName,
      "message": this.message
    };

    this.messagingService.sendChatMessage(chat)
      .subscribe(result => {

        this.result = result;

        if (!result.success) {
          // TODO: Display error
          return;
        }

        var newMessage = [new ChatMessage(this.player.name, this.recipient.name, this.recipient.groupName, this.message, 'just now', true)];
        this.chatMessages = newMessage.concat(this.chatMessages);

        this.message = '';
      });
  }

}
