import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { AuthenticationService } from "./authentication.service";
import { Constants } from '../_shared/constants';
import { Asset } from '../_models/asset';
import { Result } from '../_models/result';
import { AssetResult } from '../_models/assetResult';

@Injectable({ providedIn: 'root' })
export class AssetService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) { }

  getAssets(): Observable<Result<Asset[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<Asset[]>>(
      Constants.ApiRoot + Constants.AssetService, { 'headers': headers });
  }

  buyAsset(id: number): Observable<Result<AssetResult>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.post<Result<AssetResult>>(
      Constants.ApiRoot + Constants.BuyAssetService, id, { 'headers': headers });
  }

  sellAsset(id: number): Observable<Result<AssetResult>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.delete<Result<AssetResult>>(
      Constants.ApiRoot + Constants.SellAssetService + '\\' + id, { 'headers': headers });
  }

}
