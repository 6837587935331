import { Component, AfterViewInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-page05',
  templateUrl: './page05.component.html',
  styleUrls: ['./page05.component.css'],
})
export class Page05Component implements AfterViewInit {

  @ViewChild(NgbPopover) p1: NgbPopover;
  @ViewChild(TemplateRef) popContent5_1: any;

  constructor(
    private title: Title) {
    title.setTitle("Play Space Pirates! Tutorial");
  }

  ngAfterViewInit() {
    this.p1.ngbPopover = this.popContent5_1;
    this.openPopover(this.p1);
  }

  openPopover(p: NgbPopover): void {
    p.open();
  }

  closePopover(p: NgbPopover): void {
    p.close();
  }

}
