<div class="callout">

  <div class="submenu">
    <nav>
      <ul class="nav justify-content-center">
        <li class="nav-item"><a [routerLink]="" (click)="home()">Home</a>&nbsp;</li>
        <li class="nav-item"><a [routerLink]="" (click)="contribute()">Contribute</a>&nbsp;</li>
        <li class="nav-item"><a [routerLink]="" (click)="store()">Store</a>&nbsp;</li>
        <li class="nav-item"><a href="https://WWW.playspacepirates.com/wiki" target="_blank">Wiki/Help</a></li>
        <li class="nav-item"><a href="https://support.playspacepirates.com" target="_blank">Feedback</a></li>
        <li class="nav-item"><a href="https://www.facebook.com/PlaySpacePirates" target="_blank">Facebook</a></li>
      </ul>
    </nav>
  </div>

</div>
