import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TaskService } from '../_services/task.service';
import { TaskCategory } from '../_models/taskCategory';
import { Datastore } from '../_models/datastore';
import { DatastoreService } from '../_services/datastore.service';

@Component({
  selector: 'app-tasks-sub-menu',
  templateUrl: './tasks-sub-menu.component.html',
  styleUrls: ['./tasks-sub-menu.component.css']
})
export class TasksSubMenuComponent implements OnInit {

  data: Datastore;
  categories: TaskCategory[];

  constructor(private taskService: TaskService,
    private datastore: DatastoreService) {
    this.datastore.register().subscribe((data) => {
      this.data = data;
    });
  }

  ngOnInit() {
    this.taskService.getCategories()
      .subscribe(result => {
        this.categories = result.value;
      });
  }

  public navigate(categoryId: number): void {
    this.datastore.updateTaskMenuId(categoryId);
  }

}
