<div class="fixed-bottom container" style="max-width: 900px; background-color: black;">
  <ngb-alert type="secondary" [dismissible]="false" style="background-color: black; border: none">
      <div class="row">
        <div class="col">
          <button class="form-button" routerLink="/tutorial/1">Back</button>
        </div>
        <div class="col">
          <button class="form-button" routerLink="/guest">Skip</button>
        </div>
        <div class="col">
          <button class="form-button" routerLink="/tutorial/3">Next</button>
        </div>
      </div>
  </ngb-alert>
</div>

<ng-template #popContent2_1>
  <div class="pop">
    <h2>Missions</h2>
    <p>Follow the story line and earn some quick Credits.</p>
    <p>Executing Missions earns Credits and Experience so you can level up fast.</p>
    <div class="text-right"><button class="form-button" (click)="closePopover(p2_1); openPopover(p2_2);">Ok</button></div>
  </div>
</ng-template>

<ng-template #popContent2_2>
  <div class="pop">
    <h3>Loot</h3>
    <p>Some Missions earn or &quot;loot&quot; Booty. This Mission randomly drops a Machine Pistol.</p>
    <div class="text-right"><button class="form-button" (click)="closePopover(p2_2); openPopover(p2_3);">Ok</button></div>
  </div>
</ng-template>

<ng-template #popContent2_3>
  <div class="pop">
    <h2>Requirements</h2>
    <p>This Mission requires an Angry Monkey. You’ll need to buy all required Booty before you can execute a Mission.</p>
    <div class="text-right"><button class="form-button" routerLink="/tutorial/3">Next</button></div>
  </div>
</ng-template>

  <div class="header">
  <div class="main-menu">
    <ul>
      <li class="grayed-out"><img width="33" src="..\..\assets\images\Icons\Menus\Home.gif"><br>Port</li>
      <li class="main-menu-selected" #p2_1="ngbPopover" [ngbPopover]="popContent2_1" placement="bottom" triggers="manual" [autoClose]="false"><img width="25" src="..\..\assets\images\Icons\Menus\Tasks.gif"><br>Mission</li>
      <li class="grayed-out"><img width="27" src="..\..\assets\images\Icons\Menus\Fight.gif"><br>Battle</li>
      <li class="grayed-out"><img width="24" src="..\..\assets\images\Icons\Menus\Enhancements.gif"><br>Booty</li>
      <li class="grayed-out"><img width="31" src="..\..\assets\images\Icons\Menus\More.gif"><br>More</li>
    </ul>
  </div>
</div>
<div class="dashboard">
  <table width="100%" border="0">
    <tbody>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Credits.jpg"></td>
        <td align="right">Credits:</td>
        <td align="left" nowrap="">
          ç<span id="credits">2,500</span>
        </td>
        <td width="50%"></td>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Health.jpg"></td>
        <td align="right">Health:</td>
        <td align="left" nowrap="">
          <span id="healthPoints">100</span><span style="font-size:x-small">/100<br></span>
        </td>
      </tr>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Fights.jpg"></td>
        <td align="right">Fights:</td>
        <td align="left" nowrap="">
          <span id="fightPoints">5</span><span style="font-size:x-small">/5</span>
        </td>
        <td></td>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Skill.jpg"></td>
        <td align="right">Skill:</td>
        <td align="left" nowrap="">0</td>
      </tr>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Tasks.jpg"></td>
        <td align="right">Missions:</td>
        <td align="left" nowrap="">
          <span id="taskPoints">20</span><span style="font-size:x-small">/20</span>
        </td>
        <td></td>
        <td align="right"></td>
        <td align="right"></td>
        <td align="left" nowrap=""></td>
      </tr>
      <tr><td align="left" colspan="7"><div class="progress-bar"><span style="width: 0%;"></span></div></td></tr>
      <tr><td align="center" colspan="7"> Level: 1 &nbsp; &nbsp; &nbsp; 0<span style="font-size:x-small">/10</span></td></tr>
    </tbody>
  </table>
</div>

<div>
  <div class="action">
    <img class="action-image-top" src="../../assets/images/TableTop.jpg"><div class="action-inner">
      <h2>Clean Out The Bilge Tanks</h2>
      <div>
        <div class="progress-bar"><span style="width: 33%;"></span></div>
        <br>
        <div>
          <h3>Earns</h3>
          Experience 2<br> Min: ç520<br> Max: ç780<br>&nbsp;<br>
          <p><strong style="font-size: +1;" class="action-left" #p2_2="ngbPopover" [ngbPopover]="popContent2_2" placement="bottom-left" triggers="manual" [autoClose]="false">Booty Earned: Machine Pistol</strong></p><br>
        </div>
        <div class="action-right"><h3>Cost</h3><p>Mission Cost: 2</p></div>

        <table #p2_3="ngbPopover" [ngbPopover]="popContent2_3" placement="top-left" triggers="manual" [autoClose]="false">
          <tbody>
            <tr>
              <td style="vertical-align:top;"><a style="display: inline-block;"><img height="16" width="16" src="../../assets/images/Icons/Minus.gif"></a></td>
              <td style="vertical-align:top;"> &nbsp; <h3 style="display:inline-block;">Required Booty</h3></td>
            </tr>
          </tbody>
        </table>
        <div>
          <table>
            <tbody>
              <tr>
                <td style="vertical-align:middle; padding:5px;">
                </td>
                <td style="vertical-align:top; padding:5px;"><img style="border:1px solid #000000;" width="60" src="../../assets/images/EnhancementsThumbnails/AngryMonkey.jpg"></td>
                <td style="vertical-align:top; padding:5px;">
                  Angry Monkey<br>
                  <span style="font-size:x-small"> (Need 1 more)<br></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br><button class="form-button" type="button"><span class=""></span> Go ! </button>
      </div>
    </div><img class="action-image-bottom" src="../../assets/images/TableBottom.jpg">
  </div>
</div>

<div>
  <div class="action">
    <img class="action-image-top" src="../../assets/images/TableTop.jpg"><div class="action-inner">
      <h2>Serve Slop To The Crew</h2>
      <p><img class="action-image-small" src="../../assets/images/Icons/Locked.gif"><strong> LOCKED</strong></p>
    </div><img class="action-image-bottom" src="../../assets/images/TableBottom.jpg">
  </div>
</div>

<div class="submenu">
  <nav>
    <ul class="nav justify-content-center">
      <li class="nav-item">Land Lubber</li>
    </ul>
  </nav>
</div>
