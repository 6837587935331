import { Component, OnInit } from '@angular/core';
import { TaskService } from '../_services/task.service';
import { Task } from '../_models/task';
import { TaskResult } from '../_models/taskResult';
import { Result } from '../_models/Result';
import { Constants } from '../_shared/constants';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';
import { Timer } from '../_shared/timer';
import { SharedCode } from '../_shared/sharedCode';
import { CommaSeparatedNumberPipe } from '../_pipes/comma-separated-number.pipe';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {

  data: Datastore;
  tasks: Task[];
  selectedTaskId: number = 0;

  constructor(private taskService: TaskService,
    private datastore: DatastoreService,
    private title: Title) {
    title.setTitle("Play Space Pirates! Missions");

    this.datastore.register().subscribe((data) => {
      this.data = data;
      if (this.data.selectedTaskMenuId != this.selectedTaskId) {
        this.loadCategory();
        this.selectedTaskId = this.data.selectedTaskMenuId;
      }
    });
  }

  ngOnInit() {
    //this.loadCategory();
  }

  public loadCategory() {
    this.taskService.getTasksByCategory(this.data.selectedTaskMenuId)
      .subscribe(result => {
        for (let task of result.value) {
          task.areRequirementsVisible = !task.hasAllRequirements;
        }
        this.tasks = result.value;
      });
  }

  public async executeTask(id: number) {
    var task = this.tasks.find(x => x.id == id);

    task.active = true;
    await new Timer().sleep(1000);

    this.taskService.executeTask(id)
      .subscribe(result => {

        if (result.success) {

          if (result.value.percentComplete == 100) {
            task.percentComplete = 0;
            task.timesCompleted++;
          } else {
            task.percentComplete = result.value.percentComplete;
          }
          this.datastore.incrementCredits(result.value.creditsReceived);
          this.datastore.incrementExperience(result.value.experienceEarned);
          this.datastore.incrementTaskCredits(-result.value.taskCreditsCost);
          task.resultText = SharedCode.addPlayerMessages(result.playerMessages) + `Success!<br />+ ç${new CommaSeparatedNumberPipe().transform(result.value.creditsReceived)} credits.<br />+ ${result.value.experienceEarned} experience. ${result.value.producedLoot ? "<br />You looted a " + result.value.lootEnhancementName + "!" : ""}`;
        } else {
          task.resultText = result.errorMessage;
        }

      });

    task.active = false;

    await new Timer().sleep(4000);
    task.resultText = null;
  }

  public showHideTaskRequirements(taskId: number): void {
    var task = this.tasks.find(x => x.id == taskId);
    task.areRequirementsVisible = ! task.areRequirementsVisible;
  }

}
