<div class="gameBoard">

  <app-header></app-header>

  <app-main-sub-menu></app-main-sub-menu>

  <app-player-name [groupName]="groupName"></app-player-name>

  <ng-container *ngIf="groupName == ''"><app-player-skills></app-player-skills></ng-container>

  <app-player-summary [groupName]="groupName"></app-player-summary>

  <app-enhancement-summary [groupName]="groupName"></app-enhancement-summary>

</div>
