import { Component, OnInit } from '@angular/core';
import { DatastoreService } from '../_services/datastore.service';
import { PlayerService } from '../_services/player.service';
import { Opponent } from '../_models/opponent';
import { Result } from '../_models/result';
import { FightResult } from '../_models/fightResult';
import { Datastore } from '../_models/datastore';
import { Constants } from '../_shared/constants'
import { Timer } from '../_shared/timer';
import { SharedCode } from '../_shared/sharedCode';
import { CommaSeparatedNumberPipe } from '../_pipes/comma-separated-number.pipe';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-fight',
  templateUrl: './fight.component.html',
  styleUrls: ['./fight.component.css']
})
export class FightComponent implements OnInit {

  data: Datastore;
  opponents: Opponent[];

  constructor(private playerService: PlayerService,
    private datastore: DatastoreService,
    private title: Title) {
      title.setTitle("Play Space Pirates! Battle");

      this.datastore.register().subscribe((data) => {
        this.data = data;
      });
  }

  ngOnInit() {
    this.playerService.getOpponents()
      .subscribe(result => {
        this.opponents = result.value;
      });
  }

  public async fight(groupName: string) {
    var opponent = this.opponents.find(x => x.groupName == groupName);

    opponent.active = true;
    await new Timer().sleep(2000);

    this.playerService.fight(groupName)
      .subscribe(result => {

        if (result.success) {

          this.datastore.incrementCredits(result.value.creditsEarned);
          this.datastore.incrementExperience(result.value.experienceEarned);
          this.datastore.incrementHealthCredits(-result.value.healthLost);
          this.datastore.incrementFightCredits(-1);
          opponent.resultText = SharedCode.addPlayerMessages(result.playerMessages) + `You ${result.value.won ? "won" : "lost"} the fight!<br />${result.value.won ? "+" : "-"} ç${new CommaSeparatedNumberPipe().transform(result.value.creditsEarned)} credits.<br />+ ${result.value.experienceEarned} experience.<br />- ${result.value.healthLost} health.<br />- ${result.value.opponentHealthLost} damage to opponent.`;
        }
        else {
          opponent.resultText = result.errorMessage;
        }

      });

    opponent.active = false;

    await new Timer().sleep(6000);
    opponent.resultText = null;
  }

  public fightingAvailableAtLevel(): number {
    return Constants.NoFightingVisibleUntilLevel;
  }

  public isFightingAvailable(): boolean {
    return !(this.data.level < Constants.NoFightingVisibleUntilLevel);
  }

}
