/// <reference path="_shared/authguard.guard.ts" />
/// <reference path="_services/player.service.ts" />
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { GuestComponent } from './guest/guest.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { HomeComponent } from './home/home.component';
import { PortComponent } from './port/port.component';
import { FooterComponent } from './footer/footer.component';
import { ScreenshotCarouselComponent } from './screenshot-carousel/screenshot-carousel.component';
import { StoreComponent } from './store/store.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PortSubMenuComponent } from './port-sub-menu/port-sub-menu.component';
import { MainSubMenuComponent } from './main-sub-menu/main-sub-menu.component';
import { SystemAlertsComponent } from './system-alerts/system-alerts.component';
import { ChatHistoryComponent } from './chat-history/chat-history.component';
import { FightHistoryComponent } from './fight-history/fight-history.component';
import { TasksComponent } from './tasks/tasks.component';
import { TasksSubMenuComponent } from './tasks-sub-menu/tasks-sub-menu.component';
import { FightComponent } from './fight/fight.component';
import { EnhancementsComponent } from './enhancements/enhancements.component';
import { EnhancementsSubMenuComponent } from './enhancements-sub-menu/enhancements-sub-menu.component';
import { MoreMenuComponent } from './more-menu/more-menu.component';
import { GroupRequestsComponent } from './group-requests/group-requests.component';
import { MinutesSecondsPipe } from './_pipes/minutes-seconds.pipe';
import { RemoveSpacesPipe } from './_pipes/remove-spaces.pipe';
import { HelpComponent } from './help/help.component';
import { ContributeComponent } from './contribute/contribute.component';
import { AchievementsComponent } from './achievements/achievements.component';
import { HospitalComponent } from './hospital/hospital.component';
import { BankComponent } from './bank/bank.component';
import { GroupsComponent } from './groups/groups.component';
import { AssetsComponent } from './assets/assets.component';
import { PlayerComponent } from './player/player.component';
import { EnhancementSummaryComponent } from './enhancement-summary/enhancement-summary.component';
import { PlayerNameComponent } from './player-name/player-name.component';
import { PlayerSummaryComponent } from './player-summary/player-summary.component';
import { PlayerSkillsComponent } from './player-skills/player-skills.component';
import { CommaSeparatedNumberPipe } from './_pipes/comma-separated-number.pipe';
import { ChatComponent } from './chat/chat.component';
import { HeaderComponent } from './header/header.component';
import { AuthguardGuard } from './_shared/authguard.guard';
import { RegisterTeaserModalComponent } from './register-teaser-modal/register-teaser-modal.component';
import { AuthInterceptor } from './_shared/auth-interceptor';
import { TutorialModule } from './tutorial/tutorial.module';


@NgModule({
  declarations: [
    AppComponent,

    // Common components
    FooterComponent,

    // Unauthorized components
    MainSubMenuComponent,
    HomeComponent,
      ScreenshotCarouselComponent,
    LoginComponent,
    StoreComponent,
    ContributeComponent,

    // Authorization components
    UserManagementComponent,
    GuestComponent,
    RegisterComponent,
    RegisterTeaserModalComponent,


    // Game components
    HeaderComponent,
    DashboardComponent,
    MainMenuComponent,
    PortComponent,
      PortSubMenuComponent,
      SystemAlertsComponent,
      ChatHistoryComponent,
      GroupRequestsComponent,
      FightHistoryComponent,
    TasksComponent,
      TasksSubMenuComponent,
    FightComponent,
    EnhancementsComponent,
      EnhancementsSubMenuComponent,

    // More components
    MoreMenuComponent,
    HelpComponent,
    BankComponent,
    HospitalComponent,
    AssetsComponent,
    AchievementsComponent,
    GroupsComponent,
      ChatComponent,
    PlayerComponent,
      EnhancementSummaryComponent,
      PlayerNameComponent,
      PlayerSummaryComponent,
      PlayerSkillsComponent,

    // Utilities
    CommaSeparatedNumberPipe,
    MinutesSecondsPipe,
    RemoveSpacesPipe,


  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    FormsModule,
    HttpClientModule,
    NgbModule,

    TutorialModule,

    RouterModule.forRoot([
      { path: 'home', component: HomeComponent },
      { path: 'guest', component: GuestComponent },
      { path: 'login', component: LoginComponent },
      { path: 'store', component: StoreComponent },
      { path: 'contribute', component: ContributeComponent },

      // Secure
      { path: '', component: PortComponent, pathMatch: 'full', canActivate: [AuthguardGuard] },
      { path: 'port', component: PortComponent, canActivate: [AuthguardGuard] },
      { path: 'tasks', component: TasksComponent, canActivate: [AuthguardGuard] },
      { path: 'fight', component: FightComponent, canActivate: [AuthguardGuard] },
      { path: 'enhancements', component: EnhancementsComponent, canActivate: [AuthguardGuard] },
      { path: 'more', component: MoreMenuComponent, canActivate: [AuthguardGuard] },
      { path: 'help', component: HelpComponent, canActivate: [AuthguardGuard]  },
      { path: 'achievements', component: AchievementsComponent, canActivate: [AuthguardGuard] },
      { path: 'bank', component: BankComponent, canActivate: [AuthguardGuard] },
      { path: 'hospital', component: HospitalComponent, canActivate: [AuthguardGuard] },
      { path: 'groups', component: GroupsComponent, canActivate: [AuthguardGuard] },
      { path: 'assets', component: AssetsComponent, canActivate: [AuthguardGuard] },
      { path: 'player', component: PlayerComponent, canActivate: [AuthguardGuard] },
      { path: 'player/:id', component: PlayerComponent, canActivate: [AuthguardGuard] },
      { path: 'chat/:id', component: ChatComponent, canActivate: [AuthguardGuard] },
      { path: 'register', component: RegisterComponent, canActivate: [AuthguardGuard] },

      // All other requests go to home page
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ])
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    // Add all modal components to Entry Components here,,,
    RegisterTeaserModalComponent,
  ]
})
export class AppModule { }
