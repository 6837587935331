import { Component, OnInit } from '@angular/core';
import { PlayerService } from '../_services/player.service';
import { AchievementCategory } from '../_models/AchievementCategory';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.css']
})
export class AchievementsComponent implements OnInit {

  constructor(private playerService: PlayerService,
    private title: Title) {
    title.setTitle("Play Space Pirates! Achievements");
  }


  achievements: AchievementCategory[];

  ngOnInit() {
    this.playerService.getAchievements()
      .subscribe(result => {
        this.achievements = result.value;
      });
  }

}
