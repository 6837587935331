import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { AuthenticationService } from "./authentication.service";
import { Constants } from '../_shared/constants';
import { EnhancementCategory } from '../_models/enhancementCategory';
import { Enhancement } from '../_models/enhancement';
import { Result } from '../_models/result';
import { EnhancementSummary } from '../_models/enhancementSummary';
import { EnhancementResult } from '../_models/enhancementResult';

@Injectable({ providedIn: 'root' })
export class EnhancementService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) { }

  getCategories(): Observable<Result<EnhancementCategory[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<EnhancementCategory[]>>(
      Constants.ApiRoot + Constants.EnhancementCategoryService, { 'headers': headers });
  }

  getEnhancementsByCategory(id: number): Observable<Result<Enhancement[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<Enhancement[]>>(
      Constants.ApiRoot + Constants.EnhancementsByCategoryService + "\\" + id, { 'headers': headers });
  }

  getEnhancementSummary(groupName: string = ''): Observable<Result<EnhancementSummary[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    var groupNameParam = (groupName != '') ? ('/' + groupName) : '';

    return this.http.get<Result<EnhancementSummary[]>>(
      Constants.ApiRoot + Constants.EnhancementSummary + groupNameParam, { 'headers': headers });
  }

  buyEnhancement(id: number): Observable<Result<EnhancementResult>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.post<Result<EnhancementResult>>(
      Constants.ApiRoot + Constants.BuyEnhancementService, id, { 'headers': headers });
  }

  sellEnhancement(id: number): Observable<Result<EnhancementResult>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.delete<Result<EnhancementResult>>(
      Constants.ApiRoot + Constants.SellEnhancementService + '\\' + id, { 'headers': headers });
  }

}
