import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../_services/messaging.service';

@Component({
  selector: 'app-system-alerts',
  templateUrl: './system-alerts.component.html',
  styleUrls: ['./system-alerts.component.css']
})
export class SystemAlertsComponent implements OnInit {

  constructor(private messagingService: MessagingService) { }

  messages: string[];

  ngOnInit() {
    this.messagingService.getSystemMessages()
      .subscribe( result => {
        this.messages = result.value;
      });
  }

}
