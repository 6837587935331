import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contribute',
  templateUrl: './contribute.component.html',
  styleUrls: ['./contribute.component.css']
})
export class ContributeComponent implements OnInit {

  constructor(
    private router: Router,
    private title: Title) {
    title.setTitle("Play Space Pirates! Contribute");
  }

  ngOnInit() {
  }

  patreon() {
    window.open("https://www.patreon.com/bePatron?u=36876830", "_blank");
  }

}
