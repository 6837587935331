<div class="gameBoard">

  <app-header></app-header>

  <app-main-sub-menu></app-main-sub-menu>

  <div class="callout">
    <h1>Crew Members</h1>
    You'll need a big crew for epic battles in space!
    Add more members to your crew to increase your fighting ability.
  </div>

  <!-- Add Group Member -->
  <form #addCrew="ngForm">

    <div class="callout">
      <label for="groupName">Enter New Crew Member's Player Id:</label><br />&nbsp;<br />
      <input type="text" name="GroupName" id="GroupName" [(ngModel)]="groupName" /> &nbsp;
      <button (click)="add()" [disabled]="adding" class="form-button" type="button">
        <span [className]="adding ? 'spinner-border spinner-border-sm' : ''"></span>
        {{adding ? 'Adding...' : 'Add'}}
      </button>
      <ngb-alert *ngIf="addResultText" (close)="addResultText = null" type="danger">
        <div [innerHTML]="addResultText"></div>
      </ngb-alert>
    </div>

  </form>

  <app-group-requests></app-group-requests>

  <ng-container *ngIf="this.members && this.members.length > 0">
    <div class="callout" *ngFor="let member of members">
      <h2><a [routerLink]="['/player', member?.groupName]">{{member?.name}}</a></h2>
      <button class="form-button" (click)="goToChat(member?.groupName)">Chat</button><br />&nbsp;<br />
      Level: {{member?.level}}<br />
      Group Size: {{member?.groupSize}}<br />
      <p>Player Id: {{member?.groupName}}</p>
      <p>
        <button (click)="remove(member?.groupName)" [disabled]="member.removing" class="form-button" type="button">
          <span [className]="member.removing ? 'spinner-border spinner-border-sm' : ''"></span>
          {{member.removing ? 'Removing...' : 'Remove'}}
        </button>
        <ngb-alert *ngIf="member.resultText" (close)="member.resultText = null" type="danger">
          <div [innerHTML]="member.resultText"></div>
        </ngb-alert>
      </p>
    </div>
  </ng-container>

</div>
