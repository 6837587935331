import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Datastore } from '../_models/datastore';
import { Constants } from '../_shared/constants';
import { AuthenticationService } from '../_services/authentication.service';
import { ModalService } from '../_services/modal.service';

@Injectable({ providedIn: 'root' })
export class DatastoreService {

  private datastore: BehaviorSubject<Datastore> =
    new BehaviorSubject<Datastore>(new Datastore());

  private data: Datastore = new Datastore();

  constructor(
    private authenticationService: AuthenticationService,
    private modalService: ModalService) {
    this.datastore.subscribe((data) => {
      this.data = data;
    });
  }

  public register() {
    return this.datastore.asObservable();
  }

  public initializeDatastore(datastore: Datastore) {

    datastore.role = this.authenticationService.getUserRole();

    var selectedTaskCategory = parseInt(localStorage.getItem(Constants.LastTaskCategoryKey), 10);
    if (isNaN(selectedTaskCategory)) {
      selectedTaskCategory = Constants.DefaultTaskCategoryId;
      localStorage.setItem(Constants.LastTaskCategoryKey, "" + selectedTaskCategory + "");
    }
    datastore.selectedTaskMenuId = selectedTaskCategory;

    var selectedEnhancementCategory = parseInt(localStorage.getItem(Constants.LastEnhancementCategoryKey), 10);
    if (isNaN(selectedEnhancementCategory)) {
      selectedEnhancementCategory = Constants.DefaultEnhancementCategoryId;
      localStorage.setItem(Constants.LastEnhancementCategoryKey, "" + selectedEnhancementCategory + "");
    }
    datastore.selectedEnhancementMenuId = selectedEnhancementCategory;

    // Display a reminder to register after this level.
    if (datastore.role === Constants.RoleGuest) {
      var nextRegistrationTeaser = parseInt(localStorage.getItem(Constants.RegistrationTeaserLevelKey), 10);
      if (isNaN(nextRegistrationTeaser)) {
        nextRegistrationTeaser = Constants.InitialGuestRegistrationTeaserLevel;
        localStorage.setItem(Constants.RegistrationTeaserLevelKey, "" + nextRegistrationTeaser + "");
      }
      datastore.guestRegistrationTeaserLevel = nextRegistrationTeaser;
    }

    this.data = datastore;
    this.datastore.next(this.data);
  }

  public incrementExperience(by: number) {
    this.data.experience += by;
    this.data.levelPercentComplete =
      this.data.level ?
        Math.round((this.data.experience - this.data.levelStartsAt) /
        (this.data.levelEndsAt - this.data.levelStartsAt) * 100)
        : 0;

    if (this.data.levelEndsAt && this.data.experience >= this.data.levelEndsAt) {
      this.data.level++;
      this.data.taskCredits = this.data.maxTaskCredits;
      this.data.fightCredits = this.data.maxFightCredits;
      this.data.healthCredits = this.data.maxHealthCredits;
      this.data.skillPoints += 3;
      this.data.levelEndsAt = this.data.experience * 1.6;  // Hack - fix by getting actual next level ends at. Maybe return from service call?

      // Check if we need to show the registration teaser modal
      if (this.data.role === Constants.RoleGuest && this.data.level >= this.data.guestRegistrationTeaserLevel) {
        this.modalService.showRegistrationTeaserModal(this.data);
        this.updateRegistrationTeaserLevel();
      }
    }
    this.datastore.next(this.data);
  }

  public incrementCredits(by: number) {
    this.data.credits += by;
    this.datastore.next(this.data);
  }

  public incrementFightCredits(by: number) {
    this.data.fightCredits += by;
    this.datastore.next(this.data);
  }

  public incrementMaxFightCredits(by: number) {
    // 'by' can be + or -
    // Increment their max AND increase their current value. 
    this.data.maxFightCredits += by;
    this.data.fightCredits += by;
    this.data.skillPoints -= by;
    this.datastore.next(this.data);
  }

  public incrementHealthCredits(by: number) {
    this.data.healthCredits += by;
    this.datastore.next(this.data);
  }

  public incrementMaxHealthCredits(by: number) {
    // 'by' can be + or -
    // Increment their max AND increase their current value.
    this.data.maxHealthCredits += by;
    this.data.healthCredits += by;
    this.data.skillPoints -= by;
    this.datastore.next(this.data);
  }

  public incrementTaskCredits(by: number) {
    this.data.taskCredits += by;
    this.datastore.next(this.data);
  }

  public incrementMaxTaskCredits(by: number) {
    // 'by' can be + or -
    // Increment their max AND increase their current value.
    this.data.maxTaskCredits += by;
    this.data.taskCredits += by;
    this.data.skillPoints -= by;
    this.datastore.next(this.data);
  }

  public incrementMaxOffenseCredits(by: number) {
    // 'by' can be + or -
    // Increment their max. There is no current value displayed.
    this.data.offensePoints += by;
    this.data.skillPoints -= by;
    this.datastore.next(this.data);
  }

  public incrementMaxDefenseCredits(by: number) {
    // 'by' can be + or -
    // Increment their max. There is no current value displayed.
    this.data.defensePoints += by;
    this.data.skillPoints -= by;
    this.datastore.next(this.data);
  }

  public incrementAssetIncome(by: number) {
    this.data.assetIncome += by;
    this.datastore.next(this.data);
  }

  public updateTaskMenuId(id: number) {
    localStorage.setItem(Constants.LastTaskCategoryKey, "" + id + "");
    this.data.selectedTaskMenuId = id;
    this.datastore.next(this.data);
  }

  public updateEnhancementMenuId(id: number) {
    localStorage.setItem(Constants.LastEnhancementCategoryKey, "" + id + "");
    this.data.selectedEnhancementMenuId = id;
    this.datastore.next(this.data);
  }

  public updateRegistrationTeaserLevel() {
    if (this.data.role === Constants.RoleGuest) {
      var nextLevel = parseInt(localStorage.getItem(Constants.RegistrationTeaserLevelKey), 10) + Constants.InitialGuestRegistrationTeaserLevel;
      localStorage.setItem(Constants.RegistrationTeaserLevelKey, "" + nextLevel + "");
      this.data.guestRegistrationTeaserLevel = nextLevel;
      this.datastore.next(this.data);
    }
  }

}
