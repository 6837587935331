import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-port-sub-menu',
  templateUrl: './port-sub-menu.component.html',
  styleUrls: ['./port-sub-menu.component.css']
})
export class PortSubMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  goToRoute(id) {
    document.getElementById(id).scrollIntoView();
  }

}
