<div class="fixed-bottom container" style="max-width: 900px; background-color: black;">
  <ngb-alert type="secondary" [dismissible]="false" style="background-color: black; border: none">
      <div class="row">
        <div class="col">
          <button class="form-button" routerLink="/tutorial/4">Back</button>
        </div>
        <div class="col">
          <button class="form-button" routerLink="/guest">Skip</button>
        </div>
        <div class="col">
          <button class="form-button" routerLink="/tutorial/6">Next</button>
        </div>
      </div>
  </ngb-alert>
</div>

<ng-template #popContent5_1>
  <div class="pop">
    <h2>Battle</h2>
    <p>Build your Offense and Defense strength. After a few levels, you'll get to Battle other pirates.</p>
    <p>You’ll be battling real players, so be ready. It’s kill or be killed out there.</p>
    <div class="text-right"><button class="form-button" routerLink="/tutorial/6">Next</button></div>
  </div>
</ng-template>

<div class="header">
  <div class="main-menu">
    <ul>
      <li class="grayed-out"><img width="33" src="..\..\assets\images\Icons\Menus\Home.gif"><br>Port</li>
      <li class="grayed-out"><img width="25" src="..\..\assets\images\Icons\Menus\Tasks.gif"><br>Mission</li>
      <li class="main-menu-selected" #p1="ngbPopover" [ngbPopover]="popContent5_1" placement="bottom" triggers="manual" [autoClose]="false"><img width="27" src="..\..\assets\images\Icons\Menus\Fight.gif"><br>Battle</li>
      <li class="grayed-out"><img width="24" src="..\..\assets\images\Icons\Menus\Enhancements.gif"><br>Booty</li>
      <li class="grayed-out"><img width="31" src="..\..\assets\images\Icons\Menus\More.gif"><br>More</li>
    </ul>
  </div>
</div>
<div class="dashboard">
  <table width="100%" border="0">
    <tbody>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Credits.jpg"></td>
        <td align="right">Credits:</td>
        <td align="left" nowrap="">
          ç<span id="credits">2,500</span>
        </td>
        <td width="50%"></td>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Health.jpg"></td>
        <td align="right">Health:</td>
        <td align="left" nowrap="">
          <span id="healthPoints">100</span><span style="font-size:x-small">/100<br></span>
        </td>
      </tr>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Fights.jpg"></td>
        <td align="right">Fights:</td>
        <td align="left" nowrap="">
          <span id="fightPoints">5</span><span style="font-size:x-small">/5</span>
        </td>
        <td>
        </td>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Skill.jpg"></td>
        <td align="right">Skill:</td>
        <td align="left" nowrap="">0</td>
      </tr>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Tasks.jpg"></td>
        <td align="right">Missions:</td>
        <td align="left" nowrap="">
          <span id="taskPoints">20</span><span style="font-size:x-small">/20</span>
        </td>
        <td></td>
        <td align="right"></td>
        <td align="right"></td>
        <td align="left" nowrap=""></td>
      </tr>
      <tr><td align="left" colspan="7"><div class="progress-bar"><span style="width: 0%;"></span></div></td></tr>
      <tr><td align="center" colspan="7"> Level: 1 &nbsp; &nbsp; &nbsp; 0<span style="font-size:x-small">/10</span></td></tr>
    </tbody>
  </table>
</div>

<div class="callout"><h2>Fight!</h2><h3>Give 'em yer broadsides!</h3></div>

<div><div class="action"><img class="action-image-top" src="../../assets/images/TableTop.jpg"><div class="action-inner"><h2><span>Black Beard</span></h2> Level: 7<br> Group Size: 1<br> Player Id: A1B2C3<br>&nbsp;<br><button class="form-button" type="button"> Fight ! </button></div><img class="action-image-bottom" src="../../assets/images/TableBottom.jpg"></div></div>

<div><div class="action"><img class="action-image-top" src="../../assets/images/TableTop.jpg"><div class="action-inner"><h2><span>Gentle Ben</span></h2> Level: 6<br> Group Size: 1<br> Player Id: X9Y8Z7<br>&nbsp;<br><button class="form-button" type="button"> Fight ! </button></div><img class="action-image-bottom" src="../../assets/images/TableBottom.jpg"></div></div>

<div><div class="action"><img class="action-image-top" src="../../assets/images/TableTop.jpg"><div class="action-inner"><h2><span>Davie Jones</span></h2> Level: 10<br> Group Size: 1<br> Player Id: 9F8G7H<br>&nbsp;<br><button class="form-button" type="button"> Fight ! </button></div><img class="action-image-bottom" src="../../assets/images/TableBottom.jpg"></div></div>
