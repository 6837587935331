<div class="submenu">
  <nav>
    <ul class="nav justify-content-center">
      <li class="nav-item"><a [routerLink]="['.']" fragment="Alerts" (click)="goToRoute('Alerts')">Alerts</a></li>
      <li class="nav-item"><a [routerLink]="['.']" fragment="Requests" (click)="goToRoute('Requests')">Requests</a></li>
      <li class="nav-item"><a [routerLink]="['.']" fragment="Chat" (click)="goToRoute('Chat')">Chat</a></li>
      <li class="nav-item"><a [routerLink]="['.']" fragment="Fights" (click)="goToRoute('Fights')">Fights</a></li>
    </ul>
  </nav>
</div>
