<div class="dashboard">
    <table width="100%" border="0">
      <tr>
        <td align="right"><img src="../../assets/images/Icons/Credits.jpg" width="15" height="15" /></td>
        <td align="right">Credits:</td>
        <td align="left" nowrap>
          ç<span id="credits">{{data?.credits | commaSeparatedNumber}}</span>
          <div *ngIf="data?.assetIncome > 0">
            <span style="font-size:x-small">
              <span id="creditsTime">({{dashboard?.assetIncrementSecondsRemaining | minutesSeconds}})</span>
            </span>
          </div>
        </td>
        <td width="50%"></td>
        <td align="right"><img src="../../assets/images/Icons/Health.jpg" width="15" height="15" /></td>
        <td align="right">Health:</td>
        <td align="left" nowrap><span id="healthPoints">{{data?.healthCredits}}</span><span style="font-size:x-small">/{{data?.maxHealthCredits}}
          <br /><span id="healthTime" *ngIf="data?.healthCredits < data?.maxHealthCredits">({{dashboard?.healthIncrementSecondsRemaining | minutesSeconds}})</span></span>
        </td>
      </tr>

      <tr>
        <td align="right"><img src="../../assets/images/Icons/Fights.jpg" width="15" height="15" /></td>
        <td align="right">Fights:</td>
        <td align="left" nowrap>
          <span id="fightPoints">{{data?.fightCredits}}</span><span style="font-size:x-small">/{{data?.maxFightCredits}} <span id="fightTime" *ngIf="data?.fightCredits < data?.maxFightCredits">({{dashboard?.fightIncrementSecondsRemaining | minutesSeconds}})</span></span>
        </td>
        <td></td>
        <td align="right"><img src="../../assets/images/Icons/Skill.jpg" width="15" height="15" /></td>
        <td align="right">Skill:</td>
        <td align="left" nowrap>{{data?.skillPoints}}</td>
      </tr>

      <tr>
        <td align="right"><img src="../../assets/images/Icons/Tasks.jpg" width="15" height="15" /></td>
        <td align="right">Missions:</td>
        <td align="left" nowrap><span id="taskPoints">{{data?.taskCredits}}</span><span style="font-size:x-small">/{{data?.maxTaskCredits}} <span id="taskTime" *ngIf="data?.taskCredits < data?.maxTaskCredits">({{dashboard?.taskIncrementSecondsRemaining | minutesSeconds}})</span></span>
        <td></td>
        <td align="right"></td>
        <td align="right"></td>
        <td align="left" nowrap></td>
      </tr>

      <tr>
        <td colspan="7" align="left">
          <div class="progress-bar"><span [style.width.%]="data?.levelPercentComplete"></span></div>
        </td>
      </tr>

      <tr>
        <td colspan="7" align="center">
          Level: {{data?.level}}  &nbsp; &nbsp; &nbsp; {{data?.experience | commaSeparatedNumber}}<span style="font-size:x-small">/{{dashboard?.levelEndsAt | commaSeparatedNumber}}</span>
        </td>
      </tr>


    </table>
</div>
