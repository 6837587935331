<div class="gameBoard">

  <app-header></app-header>

  <app-main-sub-menu></app-main-sub-menu>

  <div class="callout">

    <h2><a [routerLink]="" (click)="help()">Help</a></h2>
    Tips on how to play and a little background on pirates in space.
    <br />&nbsp;<br />

    <h2><a [routerLink]="" (click)="player()">Player</a> &nbsp;/&nbsp; <a [routerLink]="" (click)="action()">{{actionString}}</a></h2>
    See how your player stacks up. You can also allocate your skill points here.
    <br />&nbsp;<br />

    <h2><a [routerLink]="" (click)="assets()">Assets</a></h2>
    Enter the black market to establish a regular income.
    <br />&nbsp;<br />

    <h2><a [routerLink]="" (click)="bank()">Buried Treasure</a></h2>
    Pirates love to bury their treasure, and for good reason too.
    <br />&nbsp;<br />

    <h2><a [routerLink]="" (click)="hospital()">Medic</a></h2>
    After getting beat up in a long battle, stop here for a little R&R.
    <br />&nbsp;<br />

    <h2><a [routerLink]="" (click)="groups()">Crew</a></h2>
    Communicate with your crew members and add other pirates to your crew.
    <br />&nbsp;<br />

    <h2><a [routerLink]="" (click)="achievements()">Achievements</a></h2>
    See your various accomplishments as a pirate. Try to earn them all.
    <br />&nbsp;<br />

  </div>

</div>
