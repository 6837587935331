<div class="gameBoard">

  <app-header></app-header>

  <app-main-sub-menu></app-main-sub-menu>

  <div class="callout">
    <h1>Medic</h1>
    We'll patch ya up so you can get out and fight some more.
    But it won't be cheap!
  </div>

  <div class="callout">

    <strong>Cost to Heal: ç{{costToHeal | commaSeparatedNumber}}</strong><br />&nbsp;<br />

    <button (click)="heal()" [disabled]="active" class="form-button" type="button">
      <span [className]="active ? 'spinner-border spinner-border-sm' : ''"></span>
      {{active ? 'Healing...' : 'Heal Me'}}
    </button>
    <ngb-alert *ngIf="resultText" (close)="resultText = null" type="danger">
      <div [innerHTML]="resultText"></div>
    </ngb-alert>

  </div>

</div>
