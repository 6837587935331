<div class="gameBoard">

  <app-header></app-header>

  <app-tasks-sub-menu></app-tasks-sub-menu>

  <div *ngFor="let task of tasks">

    <div class="action">
      <img src="../../assets/images/TableTop.jpg" class="action-image-top" />
      <div class="action-inner">

        <h2>{{task?.name}}</h2>

        <ng-container *ngIf="task?.availableAtLevel > this.data.level">
          <p><img class="action-image-small" src="../../assets/images/Icons/Locked.gif" /> <strong> LOCKED</strong></p>
          <p>Available At Level {{task?.availableAtLevel}}</p>
        </ng-container>

        <div *ngIf="task?.availableAtLevel <= this.data.level">
          <div class="progress-bar"><span [style.width.%]="task?.percentComplete"></span></div>

          <div *ngIf="task?.timesCompleted > 0"><span class="small-text">Completed {{task?.timesCompleted}} times</span></div><!-- or 1 time  - no s at end of work-->
          <br />


          <div *ngIf="task?.description">
            <h3 class="hint">Hint</h3>
            <p><span class="hint">{{task?.description}}</span></p>
          </div>

          <div class="action-left">
            <h3>Earns</h3>
            Experience {{task?.experienceEarned}}<br />
            Min: ç{{task?.minCreditsEarned | commaSeparatedNumber}}<br />
            Max: ç{{task?.maxCreditsEarned | commaSeparatedNumber}}<br />

            <ng-container *ngIf="task?.producesLoot">&nbsp;<br /><p><strong>Booty Earned: {{task?.lootEnhancementName}}</strong></p></ng-container><br />
          </div>

          <div class="action-right">
            <h3>Cost</h3>
            <p>Mission Cost: {{task?.taskCreditsCost}}</p>
          </div>

          <ng-container *ngIf="task?.taskRequirements?.length > 0">

            <table>
              <tr>
                <td style="vertical-align:top;"><a style="display: inline-block;" (click)="showHideTaskRequirements(task?.id)"><img src="../../assets/images/Icons/{{task?.areRequirementsVisible ? 'Minus.gif' : 'Plus.gif'}}" width="16" height="16" /></a></td>
                <td style="vertical-align:top;">
                  &nbsp;
                  <h3 style="display:inline-block;">{{task?.hasAllRequirements ? 'Requirements Complete' : 'Required Booty'}}</h3>
                </td>
              </tr>
            </table>

            <ng-container *ngIf="task?.areRequirementsVisible">
              <div>
                <table>
                  <tr *ngFor="let requirement of task?.taskRequirements">
                    <td style="vertical-align:middle; padding:5px;">
                      <img src="../../assets/images/Icons/GreenCheckmark.gif" width="15" height="15" *ngIf="requirement?.quantityOwned >= requirement?.quantityNeeded" />
                    </td>

                    <td style="vertical-align:top; padding:5px;">
                      <img width="60" src="../../assets/images/EnhancementsThumbnails/{{requirement?.enhancementName | removeSpaces}}.jpg" style="border:1px solid #000000;" />
                    </td>

                    <td style="vertical-align:top; padding:5px;">
                      {{requirement?.enhancementName}}<br />
                      <span style="font-size:x-small" *ngIf="requirement?.quantityOwned < requirement?.quantityNeeded">
                        (Need {{requirement?.quantityNeeded - requirement?.quantityOwned}} more)<br />
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </ng-container>

          </ng-container>

          <br />

          <button (click)="executeTask(task?.id)" [disabled]="task?.active" class="form-button" type="button">
            <span [className]="task?.active ? 'spinner-border spinner-border-sm' : ''"></span>
            {{task?.active ? 'Working' : 'Go !'}}
          </button>
          <ngb-alert *ngIf="task?.resultText" (close)="task.resultText = null" type="danger">
            <div [innerHTML]="task?.resultText"></div>
          </ngb-alert>

        </div>

      </div>
      <img src="../../assets/images/TableBottom.jpg" class="action-image-bottom" />

    </div>

  </div>


  <app-tasks-sub-menu></app-tasks-sub-menu>

</div>
