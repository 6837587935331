import { Component, OnInit } from '@angular/core';
import { EnhancementService } from '../_services/enhancement.service';
import { Enhancement } from '../_models/enhancement';
import { EnhancementResult } from '../_models/enhancementResult';
import { Result } from '../_models/result';
import { Constants } from '../_shared/constants';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';
import { Timer } from '../_shared/timer';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-enhancements',
  templateUrl: './enhancements.component.html',
  styleUrls: ['./enhancements.component.css']
})
export class EnhancementsComponent implements OnInit {

  data: Datastore;
  enhancements: Enhancement[];
  selectedEnhancementId: number = 0;

  constructor(private enhancementService: EnhancementService,
    private datastore: DatastoreService,
    private title: Title ) {
    title.setTitle("Play Space Pirates! Booty");

    this.datastore.register().subscribe((data) => {
      this.data = data;
      if (this.data.selectedEnhancementMenuId != this.selectedEnhancementId) {
        this.loadCategory();
        this.selectedEnhancementId = this.data.selectedEnhancementMenuId;
      }
    });
  }

  ngOnInit() {
    //this.loadCategory();
  }

  public loadCategory() {
    this.enhancementService.getEnhancementsByCategory(this.data.selectedEnhancementMenuId)
      .subscribe(result => {
        this.enhancements = result.value;
      });
  }

  public async buyEnhancement(id: number) {
    var enhancement = this.enhancements.find(x => x.id == id);

    enhancement.buyingActive = true;
    await new Timer().sleep(1000);

    this.enhancementService.buyEnhancement(id)
      .subscribe(result => {

        if (result.success) {
          enhancement.quantityOwned = result.value.quantityOwned;
          enhancement.actualSalesCreditsValue = enhancement.actualCreditsCost; // TODO: This is the wrong value. Need to return value from server.
          enhancement.actualCreditsCost = result.value.cost;
          this.datastore.incrementCredits(-result.value.cost);
          enhancement.resultText = `You bought a ${enhancement.name}.`
        } else {
          enhancement.resultText = result.errorMessage;
        }
      });

    enhancement.buyingActive = false;

    await new Timer().sleep(3000);
    enhancement.resultText = null;
  }

  public async sellEnhancement(id: number) {
    var enhancement = this.enhancements.find(x => x.id == id);

    enhancement.sellingActive = true;
    await new Timer().sleep(1000);

    this.enhancementService.sellEnhancement(id)
      .subscribe(result => {

        if (result.success) {
          var enhancement = this.enhancements.find(x => x.id == result.value.enhancementId);
          enhancement.quantityOwned = result.value.quantityOwned;
          enhancement.actualCreditsCost = enhancement.actualSalesCreditsValue; // TODO: This is the wrong value. Need to return value from server.
          enhancement.actualSalesCreditsValue = result.value.cost;
          this.datastore.incrementCredits(result.value.cost);
          enhancement.resultText = `You sold a ${enhancement.name}.`
      } else {
        enhancement.resultText = result.errorMessage;
      }
      });

    enhancement.sellingActive = false;

    await new Timer().sleep(3000);
    enhancement.resultText = null;
  }

}
