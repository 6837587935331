import { Component, OnInit } from '@angular/core';
import { PlayerService } from '../_services/player.service';
import { PlayerCredits } from '../_models/playerCredits';
import { Result } from '../_models/result';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';
import { Timer } from '../_shared/timer';
import { SharedCode } from '../_shared/sharedCode';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.css']
})
export class BankComponent implements OnInit {

  data: Datastore;
  bankedCredits: number = 0;
  depositAmount: number;
  withdrawAmount: number;
  depositActive: boolean = false;
  depositResultText: string;
  withdrawActive: boolean = false;
  withdrawResultText: string;

  constructor(private playerService: PlayerService,
    private datastore: DatastoreService,
    private title: Title) {
    title.setTitle("Play Space Pirates! Treasure");

    this.datastore.register().subscribe((data) => {
      this.data = data;
    });
  }
  
  ngOnInit() {
    this.playerService.getCredits()
      .subscribe(result => {
        this.bankedCredits = result.value.bankedCredits;
        this.depositAmount = result.value.availableCredits;
        //this.withdrawAmount = 0; // Not initializing withdraw amount allows placeholder to show 0. 
      });
  }

  async deposit() {
    this.depositActive = true;
    await new Timer().sleep(2000);

    this.playerService.deposit(this.depositAmount)
      .subscribe(result => {

        if (result.success) {
          this.datastore.incrementCredits(-this.depositAmount);
          this.bankedCredits += (this.depositAmount * 0.9);
          this.depositAmount = this.withdrawAmount = 0;
          this.depositResultText = SharedCode.addPlayerMessages(result.playerMessages);
        } else {
          this.depositResultText = result.errorMessage;
        }
      });
    this.depositActive = false;

    await new Timer().sleep(4000);
    this.depositResultText = null;
  }

  async withdraw() {
    this.withdrawActive = true;
    await new Timer().sleep(2000);

    this.playerService.withdraw(this.withdrawAmount)
      .subscribe(result => {

        if (result.success) {
          this.datastore.incrementCredits(this.withdrawAmount);
          this.bankedCredits -= this.withdrawAmount;
          this.depositAmount = this.withdrawAmount = 0;
          this.withdrawResultText = SharedCode.addPlayerMessages(result.playerMessages);
        } else {
          this.withdrawResultText = result.errorMessage;
        }

      });
    this.withdrawActive = false;

    await new Timer().sleep(4000);
    this.withdrawResultText = null;
  }

}
