import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { Constants} from '../_shared/constants';
import { Guest } from '../_models/guest';
import { Router } from '@angular/router';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.css']
})
export class GuestComponent implements OnInit {

  model: Guest = new Guest("");
  errors: string[];
  isRegistering: boolean = false;

  private readonly jwtKey: string = Constants.JwtKey;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) { }

  ngOnInit() {
    this.errors = [];
  }

  home() {
    this.router.navigateByUrl('/home');
  }

  guest() {
    this.isRegistering = true;
    this.errors = [];

    this.authenticationService.guest(this.model).subscribe(
      success => {
        // Guest account created successfully. Now log user in as guest. 
        // Save JWT to local storage to keep user "logged in" between sessions
        localStorage.setItem(this.jwtKey, JSON.stringify({ username: this.uuid(), token: success.token }));
        location.replace('/');
      },
      error => {
        this.isRegistering = false;
        if (error.status === 400) {
          let validationErrorDictionary = error.error;
          for (var fieldName in validationErrorDictionary) {
            if (validationErrorDictionary.hasOwnProperty(fieldName)) {
              var i: number = 0;
              for (i = 0; i < validationErrorDictionary[fieldName].length; i++) {
                this.errors.push(validationErrorDictionary[fieldName][i]);
              }
            }
          }
        } else {
          this.errors.push("An error occurred. Please try again.");
        }

      });

  }

  private uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

}
