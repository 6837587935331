<div class="submenu">
  <nav>
    <ul class="nav justify-content-center">
      <li *ngFor="let category of categories" class="nav-item">
        <ng-container *ngIf="data.selectedTaskMenuId == category?.id; else elseContent">{{category?.name}}</ng-container>
        <ng-template #elseContent><a href="#" (click)="!!navigate(category?.id)" role="button">{{category?.name}}</a></ng-template>
      </li>
    </ul>
  </nav>
</div>
