import { Component, OnInit } from '@angular/core';
import { AssetService } from '../_services/asset.service';
import { Asset } from '../_models/asset';
import { AssetResult } from '../_models/assetResult';
import { PlayerService } from '../_services/player.service';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';
import { Result } from '../_models/result';
import { Timer } from '../_shared/timer';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})
export class AssetsComponent implements OnInit {

  data: Datastore;
  assets: Asset[];
  assetIncome: number;

  constructor(
    private assetService: AssetService,
    private playerService: PlayerService,
    private datastore: DatastoreService,
    private title: Title) {
    title.setTitle("Play Space Pirates! Assets");

    this.datastore.register().subscribe((data) => {
      this.data = data;
    });
  }

  ngOnInit() {
    this.assetService.getAssets()
      .subscribe(result => {
        this.assets = result.value;
      });
  }

  public async buyAsset(id: number) {
    var asset = this.assets.find(x => x.id == id);

    asset.buyingActive = true;
    await new Timer().sleep(1000);

    this.assetService.buyAsset(id)
      .subscribe(result => {

        if (result.success) {
          this.datastore.incrementCredits(-asset.actualCreditsCost);
          this.datastore.incrementAssetIncome(asset.income);

          this.assetIncome += asset.income;
          asset.quantityOwned = result.value.quantityOwned;
          asset.actualSalesCreditsValue = asset.actualCreditsCost; // TODO: This is the wrong value. Need to return value from server.
          asset.actualCreditsCost = result.value.cost;
          asset.resultText = `You bought a ${asset.name}.`
        } else {
          asset.resultText = result.errorMessage;
        }
      });

    asset.buyingActive = false;

    await new Timer().sleep(3000);
    asset.resultText = null;
  }

  public async sellAsset(id: number) {
    var asset = this.assets.find(x => x.id == id);

    asset.sellingActive = true;
    await new Timer().sleep(1000);

    this.assetService.sellAsset(id)
      .subscribe(result => {

        if (result.success) {
          var asset = this.assets.find(x => x.id == result.value.assetId);
          this.datastore.incrementCredits(asset.actualSalesCreditsValue);
          this.datastore.incrementAssetIncome(-asset.income);

          this.assetIncome -= asset.income;
          asset.quantityOwned = result.value.quantityOwned;
          asset.actualCreditsCost = asset.actualSalesCreditsValue; // TODO: This is the wrong value. Need to return value from server.
          asset.actualSalesCreditsValue = result.value.cost;
          asset.resultText = `You sold a ${asset.name}.`
        } else {
          asset.resultText = result.errorMessage;
        }
      });

    asset.sellingActive = false;

    await new Timer().sleep(3000);
    asset.resultText = null;
  }

  public getLevel(): number {
    return this.data.level;
  }

}
