<div class="fixed-bottom container" style="max-width: 900px; background-color: black;">
  <ngb-alert type="secondary" [dismissible]="false" style="background-color: black; border: none">
      <div class="row">
        <div class="col">
          <button class="form-button" routerLink="/tutorial/3">Back</button>
        </div>
        <div class="col">
          <button class="form-button" routerLink="/guest">Skip</button>
        </div>
        <div class="col">
          <button class="form-button" routerLink="/tutorial/5">Next</button>
        </div>
      </div>
  </ngb-alert>
</div>

<ng-template #popContent4_1>
  <div class="pop">
    <h2>Assets</h2>
    <p>Assets can be found under the 'More' menu.<br />
      Assets generate ongoing income. Every time you sign into the game, your Assets produce income for another 24 hours.</p>
    <div class="text-right"><button class="form-button" routerLink="/tutorial/5">Next</button></div>
  </div>
</ng-template>

<div class="header">
  <div class="main-menu">
    <ul>
      <li class="grayed-out"><img width="33" src="..\..\assets\images\Icons\Menus\Home.gif"><br>Port</li>
      <li class="grayed-out"><img width="25" src="..\..\assets\images\Icons\Menus\Tasks.gif"><br>Mission</li>
      <li class="grayed-out"><img width="27" src="..\..\assets\images\Icons\Menus\Fight.gif"><br>Battle</li>
      <li class="grayed-out"><img width="24" src="..\..\assets\images\Icons\Menus\Enhancements.gif"><br>Booty</li>
      <!--<li class="main-menu-selected" #p1="ngbPopover" [ngbPopover]="popContent4_1" placement="bottom" triggers="manual" [autoClose]="false"><img width="31" src="..\..\assets\images\Icons\Menus\More.gif"><br>More</li>-->
      <li class="main-menu-selected"><img width="31" src="..\..\assets\images\Icons\Menus\More.gif"><br>More</li>
    </ul>
  </div>
</div>
<div class="dashboard">
  <table width="100%" border="0">
    <tbody>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Credits.jpg"></td>
        <td align="right">Credits:</td>
        <td align="left" nowrap="">
          ç<span id="credits">2,500</span>
        </td>
        <td width="50%"></td>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Health.jpg"></td>
        <td align="right">Health:</td>
        <td align="left" nowrap="">
          <span id="healthPoints">100</span><span style="font-size:x-small">/100<br></span>
        </td>
      </tr>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Fights.jpg"></td>
        <td align="right">Fights:</td>
        <td align="left" nowrap="">
          <span id="fightPoints">5</span><span style="font-size:x-small">/5</span>
        </td>
        <td>
        </td>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Skill.jpg"></td>
        <td align="right">Skill:</td>
        <td align="left" nowrap="">0</td>
      </tr>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Tasks.jpg"></td>
        <td align="right">Missions:</td>
        <td align="left" nowrap="">
          <span id="taskPoints">20</span><span style="font-size:x-small">/20</span>
        </td>
        <td></td>
        <td align="right"></td>
        <td align="right"></td>
        <td align="left" nowrap=""></td>
      </tr>
      <tr><td align="left" colspan="7"><div class="progress-bar"><span style="width: 0%;"></span></div></td></tr>
      <tr><td align="center" colspan="7"> Level: 1 &nbsp; &nbsp; &nbsp; 0<span style="font-size:x-small">/10</span></td></tr>
    </tbody>
  </table>
</div>

<div class="callout"><h2>Assets</h2><p>Check back every 24 hours for your assets to continue to generate income.</p><h2>Your income: ç300/hour.</h2></div>

<div class="action">
  <img class="action-image-top" src="../../assets/images/TableTop.jpg">
  <div class="action-inner">
    <h2>Salvage Vessel</h2>
    <div class="action-left"><img class="action-image" src="../../assets/images/Assets/SalvageVessel.jpg"></div>
    <div class="action-right">
      <div #p1="ngbPopover" [ngbPopover]="popContent4_1" placement="top" triggers="manual" [autoClose]="false">
        Owned: 1<br>
        Cost: ç13,000<br>
        Sell for: ç8,000<br>
        <p>Income: ç300</p>
        <button class="form-button" type="button"><span class=""></span> Buy </button> &nbsp;
        <img class="form-button-blank" src="../../assets/images/blank.gif"><button class="form-button" type="button"><span class=""></span> Sell </button>
      </div>
    </div>
  </div>
  <img class="action-image-top" src="../../assets/images/TableBottom.jpg">
</div>

<div>
  <div class="action">
    <img class="action-image-top" src="../../assets/images/TableTop.jpg"><div class="action-inner">
      <h2>In-Orbit Power Generator</h2>
      <p><img class="action-image-small" src="../../assets/images/Icons/Locked.gif"><strong> LOCKED</strong></p>
    </div><img class="action-image-bottom" src="../../assets/images/TableBottom.jpg">
  </div>
</div>
