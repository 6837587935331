import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Page01Component } from './page01/page01.component';
import { Page02Component } from './page02/page02.component';
import { Page03Component } from './page03/page03.component';
import { Page04Component } from './page04/page04.component';
import { Page05Component } from './page05/page05.component';
import { Page06Component } from './page06/page06.component';
import { Page07Component } from './page07/page07.component';
import { Page08Component } from './page08/page08.component';

@NgModule({
  declarations: [
    Page01Component,
    Page02Component,
    Page03Component,
    Page04Component,
    Page05Component,
    Page06Component,
    Page07Component,
    Page08Component,
  ],
  imports: [
    NgbModule,
    CommonModule,
    RouterModule.forChild([
      {
        path: 'tutorial',
        children: [
          { path: '1', component: Page01Component },
          { path: '2', component: Page02Component },
          { path: '3', component: Page03Component },
          { path: '4', component: Page04Component },
          { path: '5', component: Page05Component },
          { path: '6', component: Page06Component },
          { path: '7', component: Page07Component },
          { path: '8', component: Page08Component },
        ]
      }
    ])
  ],
})
export class TutorialModule { }
