import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { AuthenticationService } from "./authentication.service";
import { Constants } from '../_shared/constants';
import { Dashboard } from '../_models/dashboard';
import { FightResult } from '../_models/fightResult';
import { Opponent } from '../_models/opponent';
import { Result } from '../_models/result';
import { AchievementCategory } from '../_models/AchievementCategory';
import { FightHistory } from '../_models/fightHistory';
import { PlayerSummary } from '../_models/playerSummary';
import { PlayerCredits } from '../_models/playerCredits';
import { AllocateSkill } from '../_models/allocateSkill';

@Injectable({ providedIn: 'root' })
export class PlayerService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) { }

  getDashboard(): Observable<Result<Dashboard>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<Dashboard>>(
      Constants.ApiRoot + Constants.PlayerDashboardService, { 'headers': headers });
  }

  getPlayerSummary(groupName: string = ''): Observable<Result<PlayerSummary>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    var groupNameParam = (groupName != '') ? ('/' + groupName) : '';

    return this.http.get<Result<PlayerSummary>>(
      Constants.ApiRoot + Constants.PlayerSummaryService + groupNameParam, { 'headers': headers });
  }

  getOpponents(): Observable<Result<Opponent[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get < Result<Opponent[]>>(
      Constants.ApiRoot + Constants.PlayerOpponentsService, { 'headers': headers });
  }

  updateSkill(skill: AllocateSkill): Observable<Result<string>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.post<Result<string>>(
      Constants.ApiRoot + Constants.UpdateSkillService, skill, { 'headers': headers });
  }

  fight(groupName: string): Observable<Result<FightResult>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    var groupNameParam = (groupName != '') ? ('/' + groupName) : '';

    return this.http.get<Result<FightResult>>(
      Constants.ApiRoot + Constants.PlayerFightService + groupNameParam, { 'headers': headers });
  }

  getFightHistory(): Observable<Result<FightHistory[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<FightHistory[]>>(
      Constants.ApiRoot + Constants.FightHistoryService, { 'headers': headers });
  }

  getCostToHeal(): Observable<Result<number>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<number>>(
      Constants.ApiRoot + Constants.HealService, { 'headers': headers });
  }

  heal(): Observable<Result<Result<string>>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.post < Result<Result<string>>>(
      Constants.ApiRoot + Constants.HealService, null, { 'headers': headers });
  }

  getCredits(): Observable<Result<PlayerCredits>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<PlayerCredits>>(
      Constants.ApiRoot + Constants.PlayerCreditsService, { 'headers': headers });
  }

  deposit(amount: number): Observable<Result<string>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.post<Result<string>>(
      Constants.ApiRoot + Constants.DepositService, amount, { 'headers': headers });
  }

  withdraw(amount: number): Observable<Result<string>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.post<Result<string>>(
      Constants.ApiRoot + Constants.WithdrawService, amount, { 'headers': headers });
  }

  getAchievements(): Observable<Result<AchievementCategory[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<AchievementCategory[]>>(
      Constants.ApiRoot + Constants.AchievementService, { 'headers': headers });
  }

}
