<div class="gameBoard">

  <app-header></app-header>

  <app-main-sub-menu></app-main-sub-menu>

  <div class="callout">
    <h1>Life as a Pirate.</h1>
    Even pirates have a code. Follow these rules and you'll live to be Cap'n in no time. Break the rules and ye'll be walkin' the plank!
    <br />&nbsp;<br />
  </div>

  <div class="callout">

    <ol>
      <li *ngFor="let item of help">
        <h2>{{item?.question}}</h2>
        {{item?.answer}}<br />&nbsp;<br />
      </li>
    </ol>

  </div>

</div>
