<div class="callout">
  <h1>Booty:</h1>
  <ul>

    <ng-container *ngIf="! this.enhancementSummaries || this?.enhancementSummaries?.length === 0">
      <li>None</li>
    </ng-container>

    <ng-container *ngIf="this.enhancementSummaries && this.enhancementSummaries.length > 0">

      <li *ngFor="let enhancement of this.enhancementSummaries">

        <ng-container *ngIf="this.isNewCategory(enhancement?.categoryName)">
          <h2>{{enhancement?.categoryName}}{{this.setCurrentCategory(enhancement?.categoryName)}}</h2>
        </ng-container>

        <table>
          <tr>
            <td style="vertical-align:top; padding:5px;"><img width="60" src="../../assets/images/EnhancementsThumbnails/{{enhancement.name | removeSpaces}}.jpg" style="border:1px solid #000000;" /></td>
            <td style="vertical-align:top; padding:5px;">
              {{enhancement?.quantityOwned}} x {{enhancement?.name}} <br />
              <span style="font-size:x-small;">
                Offense: {{enhancement?.offensePoints}}<br />
                Defense: {{enhancement?.defensePoints}}
              </span><br />
            </td>
          </tr>
        </table>
        <br />
      </li>

    </ng-container>

  </ul>

</div>
