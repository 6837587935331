<div class="gameBoard">

  <app-header></app-header>

  <app-enhancements-sub-menu></app-enhancements-sub-menu>

  <ng-container *ngFor="let enhancement of enhancements">

    <div class="action" *ngIf="enhancement.isCurrentlyForSale || enhancement.quantityOwned > 0">
      <img src="../../assets/images/TableTop.jpg" class="action-image-top" />

      <!-- TODO: Limited Edition enhancement code was here. Not copied over -->

      <div class="action-inner">

        <h2>{{enhancement?.name}}</h2>
        <div class="action-left">
          <img class="action-image" src="../../assets/images/Enhancements/{{enhancement?.name | removeSpaces}}.jpg" />
        </div>

        <div class="action-right">

          <ng-container *ngIf="enhancement?.availableAtLevel > data.level">
            <p><img class="action-image-small" src="../../assets/images/Icons/Locked.gif" /> <strong> LOCKED</strong></p>
            <p>Available At Level {{enhancement?.availableAtLevel}}</p>
          </ng-container>

          <div *ngIf="enhancement?.availableAtLevel <= data.level">
            Owned: {{enhancement?.quantityOwned}}<br />
            <span *ngIf="! enhancement?.isLooted && enhancement?.isCurrentlyForSale">Cost: ç{{enhancement?.actualCreditsCost | commaSeparatedNumber}}<br /></span>
            <span *ngIf="enhancement?.quantityOwned > 0">Sell for: ç{{enhancement?.actualSalesCreditsValue | commaSeparatedNumber}}<br /></span>
            Offense: +{{enhancement?.offensePoints}}<br />
            Defense: +{{enhancement?.defensePoints}}<br />
            <span *ngIf="enhancement?.maintenanceCostPerPayPeriod > 0">Maintenance: ç{{enhancement?.maintenanceCostPerPayPeriod | commaSeparatedNumber}}<br /></span>
            <ng-container *ngIf="enhancement?.isLooted">
              <br />&nbsp;<br />
              <strong class="action-strong">Looted</strong>
            </ng-container>
            <br />&nbsp;<br />

            <button (click)="buyEnhancement(enhancement?.id)" [disabled]="enhancement?.buyingActive" *ngIf="! enhancement?.isLooted && enhancement?.isCurrentlyForSale" class="form-button" type="button">
              <span [className]="enhancement?.buyingActive ? 'spinner-border spinner-border-sm' : ''"></span>
              {{enhancement?.buyingActive ? 'Buying' : 'Buy'}}
            </button>
            &nbsp;

            <img src="../../assets/images/blank.gif" class="form-button-blank" *ngIf="enhancement?.isLooted" />

            <button (click)="sellEnhancement(enhancement?.id)" [disabled]="enhancement?.sellingActive" *ngIf="enhancement?.quantityOwned > 0" class="form-button" type="button">
              <span [className]="enhancement?.sellingActive ? 'spinner-border spinner-border-sm' : ''"></span>
              {{enhancement?.sellingActive ? 'Selling' : 'Sell'}}
            </button>

          </div>

        </div>

        <ngb-alert *ngIf="enhancement?.resultText" (close)="enhancement.resultText = null" type="danger">
          <div [innerHTML]="enhancement?.resultText"></div>
        </ngb-alert>

      </div>

      <img src="../../assets/images/TableBottom.jpg" class="action-image-bottom" />

    </div>

  </ng-container>

  <app-enhancements-sub-menu></app-enhancements-sub-menu>

</div>
