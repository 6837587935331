<div class="container-fluid">
  <div class="row align-items-center">
    <div class="col-xs-5 col-sm-6 col-med-6 col-12" style="min-width: 325px;">
      <img src="../../assets/images/Icons/SpacePiratesLogo.jpg" alt="Space Pirates Logo" style="width: 100%; max-width: 400px; min-width: 300px;" />
    </div>
    <div class="col-xs-7 col-sm-6 col-med-6 col-12">


        <div *ngIf="! isLoggedIn() && !loggingIn" class="text-right">
          <button type="submit" (click)="tutorial()" class="form-button align-middle" style="font-size: 18px;">
            <img src="assets/images/Icons/PlayButton.png" width="52" height="50" class="align-middle" />
            &nbsp;Play&nbsp;Now...
          </button>
          <br /> &nbsp; <br />
          Have an account? <button type="submit" (click)="loggingIn = true" class="form-button" value="Login" style="font-size: 14px;">Login</button>

        </div>
        <div *ngIf="! isLoggedIn()">

          <app-login *ngIf="loggingIn"></app-login>

          <div class="text-right">
            <br />
            <span *ngIf="loggingIn"> or </span>

            <input *ngIf="loggingIn" class="form-button" type="button" (click)="cancel()" value="Cancel">
          </div>

        </div>


    </div>
  </div>
</div>
