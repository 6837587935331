import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { AuthenticationService } from "./authentication.service";
import { Constants } from '../_shared/constants';
import { TaskCategory } from '../_models/taskCategory';
import { Task } from '../_models/task';
import { TaskResult } from '../_models/taskResult';
import { Result } from '../_models/result';

@Injectable({ providedIn: 'root' })
export class TaskService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) { }

  private lastSelectedCategoryId: number = Constants.DefaultTaskCategoryId;

  public getLastSelectedCategoryId(): number {
    return this.lastSelectedCategoryId;
  }

  public setLastSelectedCategoryId(id: number) : void {
    this.lastSelectedCategoryId = id;
  }


  getCategories(): Observable<Result<TaskCategory[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<TaskCategory[]>>(
      Constants.ApiRoot + Constants.TaskCategoryService, { 'headers': headers });
  }

  getTasksByCategory(id: number): Observable<Result<Task[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<Task[]>>(
      Constants.ApiRoot + Constants.TasksByCategoryService + "\\" + id, { 'headers': headers });
  }

  executeTask(id: number): Observable<Result<TaskResult>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.post<Result<TaskResult>>(
      Constants.ApiRoot + Constants.ExecuteTaskService, id, { 'headers': headers });
  }

}
