import { Component, OnInit, Input } from '@angular/core';
import { EnhancementService } from '../_services/enhancement.service';
import { Enhancement } from '../_models/enhancement';
import { EnhancementSummary } from '../_models/enhancementSummary';

@Component({
  selector: 'app-enhancement-summary',
  templateUrl: './enhancement-summary.component.html',
  styleUrls: ['./enhancement-summary.component.css']
})
export class EnhancementSummaryComponent implements OnInit {

  constructor(private enhancementService: EnhancementService) { }

  enhancementSummaries: EnhancementSummary[];

  // Optional - groupName will be populated only if showing another player's details
  @Input() public groupName: string;

  currentCategory: string = '';

  isNewCategory(category: string): boolean {
    return category !== this.currentCategory;
  }

  setCurrentCategory(category: string): void {
    this.currentCategory = category;
    return;
  }

  ngOnInit() {
    this.enhancementService.getEnhancementSummary(this.groupName)
      .subscribe(result => {
        this.enhancementSummaries = result.value;
      });
  }

}
