<div class="container-fluid">
  <div class="row align-items-center">
    <div class="col-xs-5 col-sm-6 col-med-6 col-12" style="min-width: 325px;">
      <a [routerLink]="" (click)="home()"><img src="../../assets/images/Icons/SpacePiratesLogo.jpg" alt="Space Pirates Logo" style="width: 100%; max-width: 400px; min-width: 300px;" /></a>
    </div>
  </div>
</div>

<div class="callout">

  <h2>Create your Pirate Name</h2>
  <p>This is how other players will identify you, so your name must be unique. We recommend that you don't use personally identifiable information such as your real name, etc.</p>
  <br />

  <form (ngSubmit)="guest()" #guestForm="ngForm">

    <div class="form-group">
      <!--<label for="UserName">Pirate Name</label>-->
      <input type="text" name="UserName" id="UserName" [(ngModel)]="model.UserName" #UserName="ngModel" class="form-control" placeholder="Pirate Name" aria-describedby="UserNameHelp" required minlength="3" maxlength="25">
      <small id="UserNameHelp" class="form-text text-muted">
        Pirate names must be "G" rated.
      </small>
      <div [hidden]="UserName.valid || UserName.pristine" class="alert alert-danger">
        A valid Pirate Name is required.
      </div>
    </div>

    <p class="text-right">
    <button type="submit" [disabled]="!guestForm.form.valid || isRegistering" class="form-button">
      <span [className]="isRegistering ? 'spinner-border spinner-border-sm' : ''"></span>
      {{isRegistering ? 'Here We Go!' : 'Start!'}}
    </button>
  </p>

    <div class="alert alert-danger" role="alert" *ngIf="errors.length > 0">
      <ul>
        <li *ngFor="let error of errors">
          {{ error }}
        </li>
      </ul>
    </div>

  </form>

</div>
