import { Component } from '@angular/core';

@Component({
  selector: 'app-screenshot-carousel',
  templateUrl: './screenshot-carousel.component.html',
  styleUrls: ['./screenshot-carousel.component.css']
})
export class ScreenshotCarouselComponent {
  images = [
    "/assets/images/screenshots/2.jpg",
    "/assets/images/screenshots/3.jpg",
    "/assets/images/screenshots/5.jpg"
  ]
}
