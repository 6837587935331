<div class="gameBoard">
  <app-header></app-header>

  <app-port-sub-menu></app-port-sub-menu>

  <div style="text-align:center;">
    <img src="../../assets/images/Icons/SpacePiratesLogo.jpg" style="max-width: 500px; width: 100%" />
  </div>

  <div class="callout" style="background-color: #aaaaaa;" *ngIf="showCallout()">
    <h2 style="color: #9d0202;">Start yer adventure by executing a &quot;Mission&quot;.</h2>
  </div>

  <!-- TODO: Limited Edition sale bannar here -->

  <app-system-alerts></app-system-alerts>

  <app-group-requests></app-group-requests>

  <app-chat-history></app-chat-history>

  <app-fight-history></app-fight-history>


  <app-port-sub-menu></app-port-sub-menu>
</div>
