import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RegisterTeaserModalComponent } from '../register-teaser-modal/register-teaser-modal.component';
import { Router } from '@angular/router';
import { Datastore } from '../_models/datastore';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalReference: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private router: Router) {
  }

  public showRegistrationTeaserModal(data: Datastore) {
      this.modalReference = this.modalService.open(RegisterTeaserModalComponent, { windowClass: 'dark-modal' });
      this.modalReference.result.then(
        async (result) => {
          if (result === 'register') {
            this.router.navigate(['register']);
          }
        }
      );
  }

}

