import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) { }

  ngOnInit() {
  }

  private _loggingIn: boolean = false;
  get loggingIn(): boolean {
    return this._loggingIn;
  }
  set loggingIn(value: boolean) {
    this._loggingIn = value;
  }

  isLoggedIn() : boolean {
    return this.authenticationService.isUserLoggedIn();
  }

  tutorial() {
    this.router.navigate(['tutorial/1']);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['home']);
  }

  cancel() : void {
    this._loggingIn = false;
  }

}
