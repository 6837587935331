import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-more-menu',
  templateUrl: './more-menu.component.html',
  styleUrls: ['./more-menu.component.css']
})
export class MoreMenuComponent implements OnInit {

  data: Datastore;
  actionString: string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private datastore: DatastoreService,
    private title: Title) {
    title.setTitle("Play Space Pirates! More");

    this.datastore.register().subscribe((data) => {
        this.data = data;
        this.actionString = this.data.role === "" ? "" : (this.data.role === "Guest" ? "Create Account" : "Logout");
    });
}

  ngOnInit() {
  }

  help() {
    this.router.navigateByUrl('/help');
  }

  player() {
    this.router.navigateByUrl('/player');
  }

  action() {
    if (this.data.role === "Guest") {
      this.router.navigateByUrl('/register');
    } else {
      this.authenticationService.logout();
      this.router.navigateByUrl('/home');
    }
  }

  bank() {
    this.router.navigateByUrl('/bank');
  }

  assets() {
    this.router.navigateByUrl('/assets');
  }

  hospital() {
    this.router.navigateByUrl('/hospital');
  }

  groups() {
    this.router.navigateByUrl('/groups');
  }

  achievements() {
    this.router.navigateByUrl('/achievements');
  }

}
