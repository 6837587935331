<app-header></app-header>

<div class="callout">
  <h1>Create an Account</h1>
  <!--<h2>{{playerName}}, Don't Lose Your Progress!</h2>-->
  With an account, you can save your progress and log in from any device.<br />
</div>

<div class="callout">

  <form (ngSubmit)="register()" #registerForm="ngForm">

    <div class="form-group">
      <label for="Email">Email</label>
      <input type="email" name="Email" id="Email" [(ngModel)]="model.Email" #Email="ngModel" class="form-control" placeholder="Email Address" aria-describedby="EmailHelp" required email>
      <small id="EmailHelp" class="form-text text-muted">
        Used to recover your password. We will never sell your email!
      </small>
      <div [hidden]="Email.valid || Email.pristine" class="alert alert-danger">
        A valid Email is required.
      </div>
    </div>

    <div class="form-group">
      <label for="Password">Password</label>
      <input type="password" name="Password" id="Password" [(ngModel)]="model.Password" #Password="ngModel" class="form-control" placeholder="Password" aria-describedby="PasswordHelp" required minlength="8" maxlength="255">
      <small id="PasswordHelp" class="form-text text-muted">
        At least 8 characters - one special character and one number.
      </small>
      <div [hidden]="Password.valid || Password.pristine" class="alert alert-danger">
        A valid Password is required.
      </div>
    </div>

    <div class="form-group">
      <label for="ConfirmPassword">Confirm Password</label>
      <input type="password" name="ConfirmPassword" id="ConfirmPassword" [(ngModel)]="model.ConfirmPassword" #ConfirmPassword="ngModel" class="form-control" placeholder="Confirm Password" aria-describedby="ConfirmPasswordHelp" required minlength="8" maxlength="255">
      <div [hidden]="ConfirmPassword.valid || ConfirmPassword.pristine" class="alert alert-danger">
        The Password and Confirm Password fields must match.
      </div>
    </div>

    <p>
      We use your email to help you reset your password. On a rare occasion, we may send promotions directly from Rhino Monkey Software which you can cancel at any time.  We will never sell your email.
      View our <a href="https://www.playspacepirates.com/wiki/index.php?title=Space_Pirates:Privacy_policy" target="_blank">Privacy policy</a>.
    </p>

    <p class="text-right">
      <button type="submit" [disabled]="!registerForm.form.valid || isRegistering" class="form-button">
        <span [className]="isRegistering ? 'spinner-border spinner-border-sm' : ''"></span>
        {{isRegistering ? 'Here We Go!' : 'Register'}}
      </button>
    </p>

      <div class="alert alert-danger" role="alert" *ngIf="errors.length > 0">
        <ul>
          <li *ngFor="let error of errors">
            {{ error }}<br />
          </li>
        </ul>
      </div>

  </form>

</div>
