<div class="callout">
  <h1>Details:</h1>

  Level: {{summary?.level}}<br />
  Group Size: {{summary?.groupSize}}<br />
  Player Id: {{summary?.groupName}}<br />
  Missions Completed: {{summary?.tasksCompleted}}<br />
  Fights Won: {{summary?.fightsWon}}<br />
  Fights Lost: {{summary?.fightsLost}}<br />

</div>

