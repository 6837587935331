<ng-container *ngIf="this.requests && this.requests.length > 0">
  <a name="Requests" id="Requests"></a>
  <div class="callout">
    <h1>Crew Requests</h1>

    <ol>

      <li *ngFor="let request of requests">
        <a [routerLink]="['/player', request?.groupName]"><h2>{{request?.name}}</h2></a>
        Level: {{request?.level}}<br />
        Group Size: {{request?.groupSize}}<br />
        <p>Player Id: {{request?.groupName}}</p>
        <p>
          <button *ngIf="!request?.hideButtons" (click)="confirm(request?.groupName)" [disabled]="request?.confirming" class="form-button" type="button">
            <span [className]="request?.confirming ? 'spinner-border spinner-border-sm' : ''"></span>
            {{request?.confirming ? 'Confirming...' : 'Confirm'}}
          </button>
          &nbsp;
          <button *ngIf="!request?.hideButtons" (click)="ignore(request?.groupName)" [disabled]="request?.ignoring" class="form-button" type="button">
            <span [className]="request?.ignoring ? 'spinner-border spinner-border-sm' : ''"></span>
            {{request?.ignoring ? 'Ignoring...' : 'Ignore'}}
          </button>
        </p>
        <ngb-alert *ngIf="request?.resultText" (close)="request.resultText = null" type="danger">
          <div [innerHTML]="request?.resultText"></div>
        </ngb-alert>
      </li>

    </ol>

  </div>
</ng-container>
