<div class="callout">
  <h2>Skill Points: {{data?.skillPoints}}</h2>
  <p>Skill points are used to boost your player's ability. Complete the next level to earn more skill points.</p> &nbsp;<br />

  <table>
    <tr>
      <td colspan="2">
        <ngb-alert *ngIf="updateSkillResultText" (close)="updateSkillResultText = null" type="danger">
          <div [innerHTML]="updateSkillResultText"></div>
        </ngb-alert>
      </td>
    </tr>
    <tr>
      <td>&nbsp;<label for="increaseMaxHealthButton">Max Health: {{data?.maxHealthCredits}}</label> &nbsp; </td>
      <td>
        <ng-container *ngIf="data?.maxHealthCredits > 0"><button (click)="updateSkill(SkillCategoriesEnum.health, -1)" class="form-button">-1</button>&nbsp;&nbsp;</ng-container>
        <ng-container *ngIf="data?.skillPoints > 0"><button (click)="updateSkill(SkillCategoriesEnum.health, 1)" class="form-button">+1</button></ng-container>
        <br />&nbsp;<br />
      </td>
    </tr>
    <tr>
      <td>&nbsp;<label for="increaseMaxFightButton">Max Fights: {{data?.maxFightCredits}}</label> &nbsp; </td>
      <td>
        <ng-container *ngIf="data?.maxFightCredits > 0"><button (click)="updateSkill(SkillCategoriesEnum.fight, -1)" class="form-button">-1</button>&nbsp;&nbsp;</ng-container>
        <ng-container *ngIf="data?.skillPoints > 0"><button (click)="updateSkill(SkillCategoriesEnum.fight, 1)" class="form-button">+1</button></ng-container>
        <br />&nbsp;<br />
      </td>
    </tr>
    <tr>
      <td>&nbsp;<label for="increaseMaxTasksButton">Max Missions: {{data?.maxTaskCredits}}</label> &nbsp; </td>
      <td>
        <ng-container *ngIf="data?.maxTaskCredits > 0"><button (click)="updateSkill(SkillCategoriesEnum.task, -1)" class="form-button">-1</button>&nbsp;&nbsp;</ng-container>
        <ng-container *ngIf="data?.skillPoints > 0"><button (click)="updateSkill(SkillCategoriesEnum.task, 1)" class="form-button">+1</button></ng-container>
        <br />&nbsp;<br />
      </td>
    </tr>
    <tr>
      <td>&nbsp;<label for="increaseMaxOffenseButton">Offense Boost: {{data?.offensePoints}}</label> &nbsp; </td>
      <td>
        <ng-container *ngIf="data?.offensePoints > 0"><button (click)="updateSkill(SkillCategoriesEnum.offense, -1)" class="form-button">-1</button>&nbsp;&nbsp;</ng-container>
        <ng-container *ngIf="data?.skillPoints > 0"><button (click)="updateSkill(SkillCategoriesEnum.offense, 1)" class="form-button">+1</button></ng-container>
        <br />&nbsp;<br />
      </td>
    </tr>
    <tr>
      <td>&nbsp;<label for="increaseMaxDefenseButton">Defense Boost: {{data?.defensePoints}}</label> &nbsp; </td>
      <td>
        <ng-container *ngIf="data?.defensePoints > 0"><button (click)="updateSkill(SkillCategoriesEnum.defense, -1)" class="form-button">-1</button>&nbsp;&nbsp;</ng-container>
        <ng-container *ngIf="data?.skillPoints > 0"><button (click)="updateSkill(SkillCategoriesEnum.defense, 1)" class="form-button">+1</button></ng-container>
        <br />&nbsp;<br />
      </td>
    </tr>
  </table>
</div>
