<app-main-sub-menu></app-main-sub-menu>

<app-user-management></app-user-management>

<div class="callout">
  <h1>We need your help</h1>
  We are actively growing Space Pirates into a world-class game... but we need your help!
</div>

<div class="callout">

  <h1>Financial Contributions</h1>
  <p>If you like Space Pirates, please consider becoming a Patreon.</p>
  <p>
    <button (click)="patreon()" class="form-button" type="button">
      <img src="../../assets/images/Icons/Patreon1.png" width="100" height="25" />
    </button>
  </p>

  <p>Space Pirates is a labor of love and none of us take a salary. We would love to have funds to make significant improvements to Space Pirates and develop other games.</p>

  <p>If you like Space Pirates, and you'd like to be a part of our continued development, please consider becoming a Patreon.</p>

  &nbsp;<br />&nbsp;

  <h1>Volunteer Opportunities</h1>
  <p>We're looking for volunteers for the following opportunities. If you have some free time and a passion for any of these areas, go to our <a href="https://support.PlaySpacePirates.com" target="_blank">Feedback</a> page, click "Contact Support" and reach out to us.</p>
  <p>We're more than happy to help you and mentor you in your personal growth as well.</p>

  <ul>
    <li>Contribute to improve our <a href="https://www.PlaySpacePirates.com/wiki" target="_blank">Wiki</a> and <a href="https://support.PlaySpacePirates.com" target="_blank">Feedback</a> sites.<br />&nbsp;</li>
    <li>SEO - Search engine optimization, marketing and advertising.<br />&nbsp;</li>
    <li>Project Management.<br />&nbsp;</li>
    <li>UI/UX and Graphic Design.<br />&nbsp;</li>
    <li>QA - API testing, UI testing and regression test procedures.<br />&nbsp;</li>
    <li>Site Administration.<br />&nbsp;</li>
    <li>Software Development and Coding.<br />&nbsp;</li>
    <li>Customer support management.<br />&nbsp;</li>
    <li>Author/Writer to help create additional universes and storylines.<br />&nbsp;</li>
    <li>Illustrator - either digital or traditional to create additional graphics.<br />&nbsp;</li>
    <li>Other talented people to help.<br />&nbsp;</li>
  </ul>

  &nbsp;<br />&nbsp;

  <h2>Thank You!</h2>

  <p>Thank you so much for your generosity!</p>

  <p>We'd love to hear from you. Go to our <a href="https://support.PlaySpacePirates.com" target="_blank">Feedback</a> site and click "Contact Support" to volunteer, say hello, request a feature, or report a bug.</p>

  <p>The Rhino Monkey support team</p>

</div>
