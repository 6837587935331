export class Datastore {
  constructor(
    public role: string = "",
    public level: number = 0,
    public experience: number = 0,
    public levelStartsAt: number = 0,
    public levelEndsAt: number = 0,
    public levelPercentComplete: number = 0,
    public credits: number = 0,
    public fightCredits: number = 0,
    public healthCredits: number = 0,
    public taskCredits: number = 0,
    public assetIncome: number = 0,
    public skillPoints: number = 0,
    public maxFightCredits: number = 0,
    public maxHealthCredits: number = 0,
    public maxTaskCredits: number = 0,
    public offensePoints: number = 0,
    public defensePoints: number = 0,
    public selectedTaskMenuId: number = 0,
    public selectedEnhancementMenuId: number = 0,
    public guestRegistrationTeaserLevel: number = 0
  ) { }
}
