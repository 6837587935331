export class ChatMessage {

  constructor(

    public playerName: string,

    public name: string,

    public groupName: string,

    public message: string,

    public time: string,

    public isSender: boolean

  ) { }
}
