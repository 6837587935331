import { Component, OnInit } from '@angular/core';
import { PlayerService } from '../_services/player.service';
import { FightHistory } from '../_models/fightHistory';
import { FightResult } from '../_models/fightResult';
import { Result } from '../_models/result';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';
import { Timer } from '../_shared/timer';
import { SharedCode } from '../_shared/sharedCode';
import { CommaSeparatedNumberPipe } from '../_pipes/comma-separated-number.pipe';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fight-history',
  templateUrl: './fight-history.component.html',
  styleUrls: ['./fight-history.component.css']
})
export class FightHistoryComponent implements OnInit {

  data: Datastore;
  fightHistory: FightHistory[];

  constructor(private playerService: PlayerService,
    private datastore: DatastoreService,
    private router: Router) {
    this.datastore.register().subscribe((data) => {
      this.data = data;
    });
  }

  ngOnInit() {
    this.playerService.getFightHistory()
      .subscribe(result => {
        this.fightHistory = result.value;
      });
  }

  fight() {
    this.router.navigateByUrl('/fight');
  }

  public async fightBack(id: number) {
    var history = this.fightHistory.find(x => x.id == id);

    history.active = true;
    await new Timer().sleep(2000);

    this.playerService.fight(history.offensePlayerGroupName)
      .subscribe(result => {

        if (result.success) {
          this.datastore.incrementCredits(result.value.creditsEarned);
          this.datastore.incrementExperience(result.value.experienceEarned);
          this.datastore.incrementHealthCredits(-result.value.healthLost);
          this.datastore.incrementFightCredits(-1);
          history.resultText = SharedCode.addPlayerMessages(result.playerMessages) + `You ${result.value.won ? "won" : "lost"} the fight!<br />${result.value.won ? "+" : "-"} ç${new CommaSeparatedNumberPipe().transform(result.value.creditsEarned)} credits.<br />+ ${result.value.experienceEarned} experience.<br />- ${result.value.healthLost} health.<br />- ${result.value.opponentHealthLost} damage to opponent.`;
        } else {
          history.resultText = result.errorMessage;
        }
      });

    history.active = false;

    await new Timer().sleep(6000);
    history.resultText = null;
  }

}
