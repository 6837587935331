export class Constants {

  //public static ApiRoot = "localhost:10000/";
  public static ApiRoot = "/";

  // BETA
  //public static ApiRoot = "/beta/";

  public static JwtKey: string = "currentUser";

  public static ClickMissionTabMessageVisibleUntilLevel: number = 2;
  public static NoFightingVisibleUntilLevel: number = 4;

  public static LastTaskCategoryKey: string = "lastTaskCategory";
  public static DefaultTaskCategoryId: number = 4;
  public static LastEnhancementCategoryKey: string = "lastEnhancementCategory";
  public static DefaultEnhancementCategoryId: number = 1;

  public static RegistrationTeaserLevelKey: string = "registrationTeaserLevel";
  public static InitialGuestRegistrationTeaserLevel: number = 5;

  // Dashboard
  public static minutesBetweenFightCreditIncrement: number = 3 * 60;
  public static minutesBetweenHealthCreditIncrement: number = 1 * 60;
  public static minutesBetweenTaskCreditIncrement: number = 5 * 60;
  public static minutesBetweenAssetCreditIncrement: number = 60 * 60;


  public static RoleEveryone: string = "Everyone";
  public static RoleGuest: string = "Guest";
  public static RoleRegistered: string = "Registered";
  public static RoleMember: string = "Member";
  public static RoleAdmin: string = "Admin";

  // Services
  public static AchievementService: string = "api/player/achievements";
  public static RegisterService: string = "api/account/register";
  public static LoginService: string = "api/account/login";
  public static GuestService: string = "api/account/guest";
  public static AssetService: string = "api/asset";
  public static BuyAssetService: string = "api/asset";
  public static ChatHistoryService: string = "api/message/chat";
  public static SellAssetService: string = "api/asset";
  public static BuyEnhancementService: string = "api/enhancement";
  public static SellEnhancementService: string = "api/enhancement";
  public static EnhancementCategoryService: string = "api/enhancement/categories";
  public static EnhancementsByCategoryService: string = "api/enhancement/categories";
  public static EnhancementSummary: string = "api/enhancement/summary";
  public static HealService: string = "api/player/heal";
  public static FightHistoryService: string = "api/player/fightHistory";
  public static GroupMemberService: string = "api/message/groupmembers";
  public static GroupMemberRequestsService: string = "api/message/groupmembers/requests";
  public static HelpService: string = "api/message/help";
  public static MessagingSystemMessagesService: string = "api/message/systemMessages";
  public static PlayerDashboardService: string = "api/player/dashboard";
  public static PlayerSummaryService: string = "api/player/summary";
  public static PlayerOpponentsService: string = "api/player/opponent";
  public static PlayerCreditsService: string = "api/player/credits";
  public static DepositService: string = "api/player/credits/depositTransaction";
  public static WithdrawService: string = "api/player/credits/withdrawTransaction";
  public static PlayerFightService: string = "api/player/fight";
  public static TasksByCategoryService: string = "api/task/categories";
  public static UpdateSkillService: string = "api/player/skill";
  public static ExecuteTaskService: string = "api/task";
  public static TaskCategoryService: string = "api/task/categories";
}

