import { Component, OnInit } from '@angular/core';
import { PlayerService } from '../_services/player.service';
import { SkillCategoriesEnum } from '../_models/skillCategoriesEnum';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';
import { AllocateSkill } from '../_models/allocateSkill';
import { Timer } from '../_shared/timer';

@Component({
  selector: 'app-player-skills',
  templateUrl: './player-skills.component.html',
  styleUrls: ['./player-skills.component.css']
})
export class PlayerSkillsComponent implements OnInit {

  constructor(private playerService: PlayerService,
    private datastore: DatastoreService) {
    this.datastore.register().subscribe((data) => {
      this.data = data;
    });
  }

  data: Datastore;

  updateSkillActive: boolean = false;
  updateSkillResultText: string;

  SkillCategoriesEnum = SkillCategoriesEnum;

  ngOnInit() {
  }

  async updateSkill(skill: SkillCategoriesEnum, quantity: number) {

    var allocateSkill: AllocateSkill = {
      "category": skill,
      "quantity": quantity
    };

    this.playerService.updateSkill(allocateSkill)
      .subscribe(async result => {

        if (result.success) {
          switch (skill) {
            case SkillCategoriesEnum.health:
              this.datastore.incrementMaxHealthCredits(quantity);
              break;
            case SkillCategoriesEnum.fight:
              this.datastore.incrementMaxFightCredits(quantity);
              break;
            case SkillCategoriesEnum.task:
              this.datastore.incrementMaxTaskCredits(quantity);
              break;
            case SkillCategoriesEnum.offense:
              this.datastore.incrementMaxOffenseCredits(quantity);
              break;
            case SkillCategoriesEnum.defense:
              this.datastore.incrementMaxDefenseCredits(quantity);
              break;
            default:
              break;
          }
        } else {
          this.updateSkillResultText = result.errorMessage;
          await new Timer().sleep(4000);
          this.updateSkillResultText = null;
        }
      });
  }

}
