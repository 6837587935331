<app-header></app-header>

<app-main-sub-menu></app-main-sub-menu>

<div class="callout">
  <h1>Chat</h1>
  Organize your crew.
</div>


<div class="callout">
  <form>
    <h2><label for="chatMessage">To: {{recipient?.name}}</label></h2>

    <textarea name="Message" id="Message" [(ngModel)]="message" maxlength="255" class="form-control"></textarea><br />
    <p><i>Note: All Chat is "G" Rated. Illicit, illegal, abusive, harmful, derogatory or inflammatory chat may result in account suspension or removal. Sending a chat message implies agreement.</i></p>
    <p><button class="form-button" (click)="sendChat()">Send</button></p>
  </form>
</div>

<div class="callout">
  <h2>Conversation</h2>
  <ol *ngFor="let chat of chatMessages">

    <li>
      <p>
        <strong>
          <ng-container *ngIf="chat?.isSender">
            {{chat?.playerName}}
          </ng-container>
          <ng-container *ngIf="! chat?.isSender">
            {{chat?.name}}
          </ng-container>
          >>
        </strong>
        {{chat?.message}}
        <span class="small-text"><br />({{chat?.time}})</span>
      </p>
    </li>

  </ol>
</div>
