import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../_services/messaging.service';
import { GroupMember } from '../_models/groupMember';
import { Result } from '../_models/result';
import { SharedCode } from '../_shared/sharedCode';
import { Timer } from '../_shared/timer';

@Component({
  selector: 'app-group-requests',
  templateUrl: './group-requests.component.html',
  styleUrls: ['./group-requests.component.css']
})
export class GroupRequestsComponent implements OnInit {

  requests: GroupMember[];

  constructor(private messagingService: MessagingService) {
  }

  ngOnInit() {
    this.messagingService.getGroupRequests()
      .subscribe(result => {
        this.requests = result.value;
      });
  }

  public async confirm(groupName: string) {
    var member = this.requests.find(x => x.groupName == groupName);

    member.confirming = true;
    await new Timer().sleep(2000);

    this.messagingService.confirmGroupMember(groupName)
      .subscribe(result => {

        if (result.success) {
          member.resultText = SharedCode.addPlayerMessages(result.playerMessages);
        } else {
          member.resultText = result.errorMessage;
        }
    });

    member.confirming = false;
    member.hideButtons = true;
    await new Timer().sleep(4000);
    member.resultText = null;

    this.requests = this.requests.filter(x => x.groupName !== groupName);
  }

  public async ignore(groupName: string) {
    var member = this.requests.find(x => x.groupName == groupName);

    member.ignoring = true;
    await new Timer().sleep(2000);

    this.messagingService.removeGroupMember(groupName)
      .subscribe(result => {

        if (result.success) {
          member.resultText = SharedCode.addPlayerMessages(result.playerMessages);
        } else {
          member.resultText = result.errorMessage;
        }
    });

    member.ignoring = false;
    member.hideButtons = true;
    await new Timer().sleep(4000);
    member.resultText = null;

    this.requests = this.requests.filter(x => x.groupName !== groupName);
  }

}
