import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent {

  constructor(
    private router: Router) {
  }

  public isCurrentPage(desiredPage: string): boolean {
    return window.location.pathname.includes(desiredPage);
  }

  port() {
    if (!this.isCurrentPage('port')) {
      this.router.navigateByUrl('/port');
    }
  }

  tasks() {
    if (!this.isCurrentPage('tasks')) {
      this.router.navigateByUrl('/tasks');
    }
  }

  fight() {
    if (!this.isCurrentPage('fight')) {
      this.router.navigateByUrl('/fight');
    }
  }

  enhancements() {
    if (!this.isCurrentPage('enhancements')) {
      this.router.navigateByUrl('/enhancements');
    }
  }

  more() {
    if (!this.isCurrentPage('more')) {
      this.router.navigateByUrl('/more');
    }
  }

}
