import { Component, OnInit, Input } from '@angular/core';
import { PlayerService } from '../_services/player.service';
import { PlayerSummary } from '../_models/playerSummary';

@Component({
  selector: 'app-player-name',
  templateUrl: './player-name.component.html',
  styleUrls: ['./player-name.component.css']
})
export class PlayerNameComponent implements OnInit {

  constructor(private playerService: PlayerService) { }

  summary: PlayerSummary;

  // Optional - groupName will be populated only if showing another player's details
  @Input() public groupName: string;

  ngOnInit() {
    this.playerService.getPlayerSummary(this.groupName)
      .subscribe(result => {
        this.summary = result.value;
      });
  }

}
