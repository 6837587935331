import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-teaser-modal',
  templateUrl: './register-teaser-modal.component.html',
  styleUrls: ['./register-teaser-modal.component.css']
})
export class RegisterTeaserModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router
  ) { }

  ngOnInit() {
  }

  register() {
    this.activeModal.close('register');
  }

  later() {
    this.activeModal.dismiss('later');
  }

}
