<form (ngSubmit)="loginUser()" #loginForm="ngForm">

  <div class="form-group">
    <label for="Email">Email</label>
    <input type="email" name="Email" id="Email" [(ngModel)]="model.Email" #Email="ngModel" class="form-control" placeholder="Email" required email>
    <div [hidden]="Email.valid || Email.pristine" class="alert alert-danger">
      Your Email is required.
    </div>
  </div>

  <div class="form-group">
    <label for="Password">Password</label>
    <input type="password" name="Password" id="Password" [(ngModel)]="model.Password" #Password="ngModel" class="form-control" placeholder="Password" required minlength="8">
    <div [hidden]="Password.valid || Password.pristine" class="alert alert-danger">
      Your Password is required.
    </div>
  </div>

  <div class="text-right">
    <button type="submit" [disabled]="!loginForm.form.valid || isLoggingIn" class="form-button">
      <span [className]="isLoggingIn ? 'spinner-border spinner-border-sm' : ''"></span>
      {{isLoggingIn ? 'Here We Go!' : 'Login'}}
    </button>
  </div>

  <div class="alert alert-danger" role="alert" *ngIf="errors.length > 0">
    <ul>
      <li *ngFor="let error of errors">
        {{ error }}
      </li>
    </ul>
  </div>

</form>
