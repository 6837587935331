<div class="modal-header">
  <h1 class="modal-title">Create an Account</h1>
</div>
<div class="modal-body">
  <h3>Creating an account is free and easy!</h3>

  <p>A personal account lets you log in from any device with your own user name and password.</p>

  <p>If you don't create an account, your progress could be lost if you switch devices.</p>
</div>
<div class="modal-footer">
  <button type="button" class="form-button" (click)="later()">Remind Me Later</button> &nbsp;
  <button type="button" class="form-button btn btn-danger ngbAutofocus" style="background-color: #ab0a0a;" (click)="register()">Register Now!</button>
</div>
