<div class="gameBoard">

  <app-header></app-header>

  <app-main-sub-menu></app-main-sub-menu>

  <div class="callout">
    <h1>Treasure Chest</h1>
    <h2>Stash yer coins here.</h2>
    Buried treasure cannot be stolen during fights, but there is a small fee for burying your treasure.
  </div>

  <form #depositWithdrawForm="ngForm">

    <div class="callout">
      <label for="DepositAmount">Bury (up to ç{{data?.credits | commaSeparatedNumber}})</label><br />
      You will be charged 10% for burying your credits.<br />
      ç&nbsp;<input type="number" name="DepositAmount" id="DepositAmount" [(ngModel)]="depositAmount" />

      <button (click)="deposit()" [disabled]="depositActive" class="form-button" type="button">
        <span [className]="depositActive ? 'spinner-border spinner-border-sm' : ''"></span>
        {{depositActive ? 'Working' : 'Deposit'}}
      </button>
      <ngb-alert *ngIf="depositResultText" (close)="depositResultText = null" type="danger">
        <div [innerHTML]="depositResultText"></div>
      </ngb-alert>

      <p>&nbsp;</p>

      <label for="WithdrawAmount">Retrieve (up to ç{{bankedCredits | commaSeparatedNumber}})</label><br />
      ç&nbsp;<input type="number" name="WithdrawAmount" id="WithdrawAmount" [(ngModel)]="withdrawAmount" placeholder="0" />

      <button (click)="withdraw()" [disabled]="withdrawActive" class="form-button" type="button">
        <span [className]="withdrawActive ? 'spinner-border spinner-border-sm' : ''"></span>
        {{withdrawActive ? 'Working' : 'Withdraw'}}
      </button>
      <ngb-alert *ngIf="withdrawResultText" (close)="withdrawResultText = null" type="danger">
        <div [innerHTML]="withdrawResultText"></div>
      </ngb-alert>
    </div>

  </form>

</div>
