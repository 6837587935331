<!--<h2>Screenshots</h2>-->

<ngb-carousel *ngIf="images" style="width: 100%">
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img [src]="images[0]" alt="Space Pirates screen shot one." style="width: 100%">
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img [src]="images[1]" alt="Space Pirates screen shot two." style="width: 100%">
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img [src]="images[2]" alt="Space Pirates screen shot three." style="width: 100%">
    </div>
  </ng-template>
</ngb-carousel>

<p>&nbsp;</p>
