import { Component, OnInit } from '@angular/core';
import { PlayerService } from '../_services/player.service';
import { Result } from '../_models/result';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';
import { Timer } from '../_shared/timer';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-hospital',
  templateUrl: './hospital.component.html',
  styleUrls: ['./hospital.component.css']
})
export class HospitalComponent implements OnInit {

  data: Datastore;
  costToHeal: number;
  active: boolean = false;
  resultText: string;

  constructor(private playerService: PlayerService,
    private datastore: DatastoreService,
    private title: Title) {
    title.setTitle("Play Space Pirates! Medic");

    this.datastore.register().subscribe((data) => {
      this.data = data;
    });
  }

  ngOnInit() {
    this.playerService.getCostToHeal()
      .subscribe(result => {
        this.costToHeal = result.value;
      });
  }

  async heal() {

    this.active = true;
    await new Timer().sleep(1000);

    this.playerService.heal()
      .subscribe(result => {

        if (result.success) {
          this.datastore.incrementCredits(-this.costToHeal);
          this.datastore.incrementHealthCredits(this.data.maxHealthCredits);
          this.costToHeal = 0;

          this.resultText = result.playerMessages[0];

        } else {
          this.resultText = result.errorMessage;
        }

      });

    this.active = false;

    await new Timer().sleep(4000);
    this.resultText = null;
  }

}
