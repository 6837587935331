<div class="gameBoard">

  <app-header></app-header>

  <app-main-sub-menu></app-main-sub-menu>

  <div class="callout">
    <h1>Achievements.</h1>
    See how you're progressin' on yer journey to become Cap'n.
  </div>

  <div class="callout" *ngFor="let category of achievements">

    <h2>{{category?.name}}</h2>

    <table>
      <tr *ngFor="let achievement of category?.achievements">
        <td>
          <img src="../../assets/images/Icons/Fights.jpg" width="15" height="15" *ngIf="achievement.hasAchievement" />&nbsp;&nbsp;
        </td>
        <td><p>{{achievement?.name}}</p></td>
      </tr>
    </table>
  </div>

</div>
