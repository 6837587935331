import { Component, OnInit } from '@angular/core';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';
import { Constants } from '../_shared/constants'
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-port',
  templateUrl: './port.component.html',
  styleUrls: ['./port.component.css']
})
export class PortComponent implements OnInit {

  data: Datastore;

  constructor(
    private datastore: DatastoreService,
    private title: Title) {
    title.setTitle("Play Space Pirates! Port");

    this.datastore.register().subscribe((data) => {
      this.data = data;
    });
  }

  ngOnInit() {
  }

  public showCallout(): boolean {
    return this.data.level < Constants.ClickMissionTabMessageVisibleUntilLevel && this.data.level != 0;
  }


}

