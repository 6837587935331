<div class="gameBoard">

  <app-header></app-header>

  <app-main-sub-menu></app-main-sub-menu>

  <div class="callout">
    <h1>Assets</h1>
    <p>Establish an income on the black market. Check back within 24 hours for your assets to continue to generate income.</p>

    <h2>Your income: ç{{data.assetIncome | commaSeparatedNumber}}/hour.</h2>

  </div>

  <div class="action" *ngFor="let asset of assets">
    <img src="../../assets/images/TableTop.jpg" class="action-image-top" />

    <div class="action-inner">

      <h2>{{asset?.name}}</h2>

      <div class="action-left">
        <img class="action-image" src="../../assets/images/Assets/{{asset?.name | removeSpaces}}.jpg" />
      </div>

      <div class="action-right">

        <ng-container *ngIf="asset?.availableAtLevel > this.getLevel()">
          <p><img class="action-image-small" src="../../assets/images/Icons/Locked.gif" /> <strong> LOCKED</strong></p>
          <p>Available At Level {{asset?.availableAtLevel}}</p>
        </ng-container>

        <div *ngIf="asset?.availableAtLevel <= this.getLevel()">
          Owned: {{asset?.quantityOwned}}<br />
          Cost: ç{{asset?.actualCreditsCost | commaSeparatedNumber}}<br />
          <ng-container *ngIf="asset?.quantityOwned > 0">Sell for: ç{{asset?.actualSalesCreditsValue | commaSeparatedNumber}}<br /></ng-container>
          <p>Income: ç{{asset?.income | commaSeparatedNumber}}</p>

          <button (click)="buyAsset(asset?.id)" [disabled]="asset?.buyingActive" class="form-button" type="button">
            <span [className]="asset?.buyingActive ? 'spinner-border spinner-border-sm' : ''"></span>
            {{asset?.buyingActive ? 'Buying' : 'Buy'}}
          </button>

          &nbsp;

          <img src="../../assets/images/blank.gif" class="form-button-blank" />

          <button (click)="sellAsset(asset?.id)" [disabled]="asset?.sellingActive" *ngIf="asset?.quantityOwned > 0" class="form-button" type="button">
            <span [className]="asset?.sellingActive ? 'spinner-border spinner-border-sm' : ''"></span>
            {{asset?.sellingActive ? 'Selling' : 'Sell'}}
          </button>

        </div>

      </div>

      <ngb-alert *ngIf="asset?.resultText" (close)="asset.resultText = null" type="danger">
        <div [innerHTML]="asset?.resultText"></div>
      </ngb-alert>

    </div>

    <img src="../../assets/images/TableBottom.jpg" class="action-image-top" />

  </div>

</div>
