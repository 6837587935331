import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-sub-menu',
  templateUrl: './main-sub-menu.component.html',
  styleUrls: ['./main-sub-menu.component.css']
})
export class MainSubMenuComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  home() {
    this.router.navigateByUrl('/home');
  }

  store() {
    this.router.navigateByUrl('/store');
  }

  contribute() {
    this.router.navigateByUrl('/contribute');
  }
}
