import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EnhancementService } from '../_services/enhancement.service';
import { EnhancementCategory } from '../_models/enhancementCategory';
import { Datastore } from '../_models/datastore';
import { DatastoreService } from '../_services/datastore.service';

@Component({
  selector: 'app-enhancements-sub-menu',
  templateUrl: './enhancements-sub-menu.component.html',
  styleUrls: ['./enhancements-sub-menu.component.css']
})
export class EnhancementsSubMenuComponent implements OnInit {

  data: Datastore;
  categories: EnhancementCategory[];

  constructor(private enhancementService: EnhancementService,
    private datastore: DatastoreService) {
    this.datastore.register().subscribe((data) => {
      this.data = data;
    });
  }

  ngOnInit() {
    this.enhancementService.getCategories()
      .subscribe(result => {
        this.categories = result.value;
      });
  }

  public navigate(categoryId: number): void {
    this.datastore.updateEnhancementMenuId(categoryId);
  }

}
