<app-main-sub-menu></app-main-sub-menu>

<app-user-management></app-user-management>

<div class="callout">
  <h1>Shop our Store</h1>
  <p>Buying items from our store helps support continued development of Space Pirates and other quality games. Please consider shopping here, or supporting us on our Contribute page.</p>
  <p>We're working with our vendors to create a line of appealing, high quality products. You're going to love it!</p>
  <h2>Coming Soon!</h2>
</div>


<div>

  <div class="action">
    <img src="../../assets/images/TableTop.jpg" class="action-image-top" />


    <div class="action-inner">

      <div class="row">
        <div class="col-sm-4">
          <div class="card" style="background: #555555">
            <!-- Zazzle.com -->
            <img class="card-img-top" src="/assets/images/store/Mug.jpg" width="80%" alt="Space Pirates Mug">
            <div class="card-body">
              <!--<h5 class="card-title">Special title treatment</h5>-->
              <p class="card-text">Support continued Space Pirates development.</p>
              <button type="submit" class="form-button">Coming Soon!</button>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card" style="background: #555555">
            <img class="card-img-top" src="/assets/images/store/MousePad.jpg" width="80%" alt="Card image cap">
            <div class="card-body">
              <!--<h5 class="card-title">Special title treatment</h5>-->
              <p class="card-text">Stay tuned for our line of quality merchandise.</p>
              <button type="submit" class="form-button">Coming Soon!</button>
            </div>
          </div>
        </div>
        <!--<div class="col-sm-4">
          <div class="card">
            <img class="card-img-top" src="/assets/images/store/Shirt.jpg" width="80%" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title">Special title treatment</h5>
              <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
          </div>
        </div>-->
      </div>
    </div>
    <img src="../../assets/images/TableBottom.jpg" class="action-image-bottom" />
  </div>

</div>
