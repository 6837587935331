import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title) {
    title.setTitle("Play Space Pirates! Player");
  }

  groupName: string = ''; // Used when a group name is passed in

  ngOnInit() {
    try {
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          params.get('id'))
      ).subscribe(val => this.groupName += val);
    } catch (e) {
      /* If no group name in route. Use current player. */
    }
  }

}
