import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../_services/messaging.service';
import { Router } from '@angular/router';
import { GroupMember } from '../_models/groupMember';
import { Result } from '../_models/result';
import { Timer } from '../_shared/timer';
import { SharedCode } from '../_shared/sharedCode';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

  constructor(
    private messagingService: MessagingService,
    private router: Router,
    private title: Title) {
    title.setTitle("Play Space Pirates! Crew");
  }

  members: GroupMember[];
  groupName: string;
  addResultText: string;
  adding: boolean;

  ngOnInit() {
    this.messagingService.getGroupMembers()
      .subscribe(result => {
        this.members = result.value;
      });
  }
  
  async add() {
    if (!this.groupName ) {
      return;
    }

    this.adding = true;
    await new Timer().sleep(2000);

    this.messagingService.addGroupRequest(this.groupName)
      .subscribe(result => {

        if (result.success) {
          this.groupName = "";
          this.addResultText = SharedCode.addPlayerMessages(result.playerMessages);
        } else {
          this.addResultText = result.errorMessage;
        }
      });

    this.adding = false;
    await new Timer().sleep(4000);
    this.addResultText = null;
  }

  async remove(groupName: string) {
    if (!groupName) {
      return;
    }
    var member = this.members.find(x => x.groupName == groupName);

    member.removing = true;
    await new Timer().sleep(2000);

    this.messagingService.removeGroupMember(groupName)
      .subscribe(result => {

        if (result.success) {
          member.resultText = SharedCode.addPlayerMessages(result.playerMessages);
        } else {
          member.resultText = result.errorMessage;
        }
      });

    member.removing = false;
    await new Timer().sleep(4000);
    this.members = this.members.filter(x => x.groupName !== groupName);
    member.resultText = null;
  }

  goToChat(groupName: string): void {
    this.router.navigateByUrl('/chat/' + groupName);
  }

}
